const files = 
`<?xml version="1.0" encoding="UTF-8" ?>
<root>
    <file>
        <id_resource>1007</id_resource>
        <id_file>5559</id_file>
        <type>1</type>
        <dirname>1007</dirname>
        <basename>duplicacao_do_cubo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1025</id_resource>
        <id_file>5667</id_file>
        <type>1</type>
        <dirname>1025</dirname>
        <basename>poligonos_e_circunferencia---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1032</id_resource>
        <id_file>5709</id_file>
        <type>1</type>
        <dirname>1032</dirname>
        <basename>quanto_voce_tem_de_pele---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1361</id_resource>
        <id_file>6999</id_file>
        <type>1</type>
        <dirname>1361</dirname>
        <basename>torres_de_hanoi---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>995</id_resource>
        <id_file>16323</id_file>
        <type>1</type>
        <dirname>995</dirname>
        <basename>a_matematica_dos_calendarios---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>996</id_resource>
        <id_file>16329</id_file>
        <type>1</type>
        <dirname>996</dirname>
        <basename>arco_capaz_e_navegacao---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>997</id_resource>
        <id_file>16335</id_file>
        <type>1</type>
        <dirname>997</dirname>
        <basename>baralho_e_torradas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>998</id_resource>
        <id_file>16341</id_file>
        <type>1</type>
        <dirname>998</dirname>
        <basename>baralho_magico---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>999</id_resource>
        <id_file>16347</id_file>
        <type>1</type>
        <dirname>999</dirname>
        <basename>camara_escura---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1000</id_resource>
        <id_file>16353</id_file>
        <type>1</type>
        <dirname>1000</dirname>
        <basename>cilindro=cone+esfera2---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1001</id_resource>
        <id_file>16359</id_file>
        <type>1</type>
        <dirname>1001</dirname>
        <basename>com_quantas_cores_posso_pintar_um_mapa---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1002</id_resource>
        <id_file>16365</id_file>
        <type>1</type>
        <dirname>1002</dirname>
        <basename>como_economizar_cadarco---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1003</id_resource>
        <id_file>16371</id_file>
        <type>1</type>
        <dirname>1003</dirname>
        <basename>corrida_ao_100---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1004</id_resource>
        <id_file>16377</id_file>
        <type>1</type>
        <dirname>1004</dirname>
        <basename>curvas_de_nivel---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1005</id_resource>
        <id_file>16383</id_file>
        <type>1</type>
        <dirname>1005</dirname>
        <basename>de_quantas_maneiras_posso_passar_meu_cadarco---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1006</id_resource>
        <id_file>16389</id_file>
        <type>1</type>
        <dirname>1006</dirname>
        <basename>dinamometro_com_elastico---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1008</id_resource>
        <id_file>16401</id_file>
        <type>1</type>
        <dirname>1008</dirname>
        <basename>eliminando_quadrados---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1009</id_resource>
        <id_file>16407</id_file>
        <type>1</type>
        <dirname>1009</dirname>
        <basename>empacotamento_de_latas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1010</id_resource>
        <id_file>16413</id_file>
        <type>1</type>
        <dirname>1010</dirname>
        <basename>engenharia_de_grego---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1011</id_resource>
        <id_file>16419</id_file>
        <type>1</type>
        <dirname>1011</dirname>
        <basename>escoamento_de_areia---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1012</id_resource>
        <id_file>16425</id_file>
        <type>1</type>
        <dirname>1012</dirname>
        <basename>espelhos_e_simetrias---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1013</id_resource>
        <id_file>16431</id_file>
        <type>1</type>
        <dirname>1013</dirname>
        <basename>esqueletos_no_espaco---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1014</id_resource>
        <id_file>16437</id_file>
        <type>1</type>
        <dirname>1014</dirname>
        <basename>estatuas_e_dominos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1015</id_resource>
        <id_file>16443</id_file>
        <type>1</type>
        <dirname>1015</dirname>
        <basename>estradas_para_estacao---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1016</id_resource>
        <id_file>16449</id_file>
        <type>1</type>
        <dirname>1016</dirname>
        <basename>jankenpon---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1017</id_resource>
        <id_file>16455</id_file>
        <type>1</type>
        <dirname>1017</dirname>
        <basename>jogo_das_amebas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1018</id_resource>
        <id_file>16461</id_file>
        <type>1</type>
        <dirname>1018</dirname>
        <basename>jogo_dos_divisores---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1019</id_resource>
        <id_file>16467</id_file>
        <type>1</type>
        <dirname>1019</dirname>
        <basename>magica_das_cartelas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1020</id_resource>
        <id_file>16473</id_file>
        <type>1</type>
        <dirname>1020</dirname>
        <basename>mensagens_secretas_com_matrizes---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1021</id_resource>
        <id_file>16479</id_file>
        <type>1</type>
        <dirname>1021</dirname>
        <basename>montanhas_geometricas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1022</id_resource>
        <id_file>16485</id_file>
        <type>1</type>
        <dirname>1022</dirname>
        <basename>morto_ou_vivo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1023</id_resource>
        <id_file>16491</id_file>
        <type>1</type>
        <dirname>1023</dirname>
        <basename>o_quadrado_de_koch---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1024</id_resource>
        <id_file>16497</id_file>
        <type>1</type>
        <dirname>1024</dirname>
        <basename>onde_fica_a_lixeira---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1026</id_resource>
        <id_file>16509</id_file>
        <type>1</type>
        <dirname>1026</dirname>
        <basename>poligonos_regulares_e_ladrilhos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1027</id_resource>
        <id_file>16515</id_file>
        <type>1</type>
        <dirname>1027</dirname>
        <basename>populacao_versus_amostra---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1028</id_resource>
        <id_file>16521</id_file>
        <type>1</type>
        <dirname>1028</dirname>
        <basename>quadrado_magico_aditivo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1029</id_resource>
        <id_file>16527</id_file>
        <type>1</type>
        <dirname>1029</dirname>
        <basename>qual_e_a_area_do_quadrilatero---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1030</id_resource>
        <id_file>16533</id_file>
        <type>1</type>
        <dirname>1030</dirname>
        <basename>qual_e_o_cone_com_maior_volume---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1031</id_resource>
        <id_file>16539</id_file>
        <type>1</type>
        <dirname>1031</dirname>
        <basename>qual_e_o_prisma_com_maior_volume---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1034</id_resource>
        <id_file>16552</id_file>
        <type>1</type>
        <dirname>1034</dirname>
        <basename>series_temporais---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1035</id_resource>
        <id_file>16558</id_file>
        <type>1</type>
        <dirname>1035</dirname>
        <basename>taxi_e_combinatoria---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1037</id_resource>
        <id_file>16570</id_file>
        <type>1</type>
        <dirname>1037</dirname>
        <basename>transformacao_de_mobius---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1038</id_resource>
        <id_file>16576</id_file>
        <type>1</type>
        <dirname>1038</dirname>
        <basename>variaveis_antropometricas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1039</id_resource>
        <id_file>16582</id_file>
        <type>1</type>
        <dirname>1039</dirname>
        <basename>volume_de_piramides---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>994</id_resource>
        <id_file>17609</id_file>
        <type>1</type>
        <dirname>994</dirname>
        <basename>a_altura_da_arvore---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1033</id_resource>
        <id_file>17615</id_file>
        <type>1</type>
        <dirname>1033</dirname>
        <basename>a_roda-gigante---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1365</id_resource>
        <id_file>17627</id_file>
        <type>1</type>
        <dirname>1365</dirname>
        <basename>apostas_no_relogio---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1366</id_resource>
        <id_file>17633</id_file>
        <type>1</type>
        <dirname>1366</dirname>
        <basename>avalanches---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1367</id_resource>
        <id_file>17639</id_file>
        <type>1</type>
        <dirname>1367</dirname>
        <basename>caixa_de_papel---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1368</id_resource>
        <id_file>17645</id_file>
        <type>1</type>
        <dirname>1368</dirname>
        <basename>caminhos_e_grafos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1369</id_resource>
        <id_file>17651</id_file>
        <type>1</type>
        <dirname>1369</dirname>
        <basename>cortar_cubos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1370</id_resource>
        <id_file>17657</id_file>
        <type>1</type>
        <dirname>1370</dirname>
        <basename>dobra_a_lingua_e_coca_a_orelha---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1371</id_resource>
        <id_file>17663</id_file>
        <type>1</type>
        <dirname>1371</dirname>
        <basename>o_metodo_de_monte_carlo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1372</id_resource>
        <id_file>17669</id_file>
        <type>1</type>
        <dirname>1372</dirname>
        <basename>quadrado_magico_multiplicativo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1373</id_resource>
        <id_file>17675</id_file>
        <type>1</type>
        <dirname>1373</dirname>
        <basename>quantos_peixes_ha_no_lago---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1374</id_resource>
        <id_file>17681</id_file>
        <type>1</type>
        <dirname>1374</dirname>
        <basename>que_curva_e_essa_chamada_elipse---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1380</id_resource>
        <id_file>17716</id_file>
        <type>1</type>
        <dirname>1380</dirname>
        <basename>jogo_da_trilha---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1386</id_resource>
        <id_file>17746</id_file>
        <type>1</type>
        <dirname>1386</dirname>
        <basename>padroes_no_plano---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1036</id_resource>
        <id_file>17972</id_file>
        <type>1</type>
        <dirname>1036</dirname>
        <basename>otimizacao_da_cerca---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1007</id_resource>
        <id_file>5560</id_file>
        <type>2</type>
        <dirname>1007</dirname>
        <basename>TELA-duplicacao_do_cubo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1025</id_resource>
        <id_file>5668</id_file>
        <type>2</type>
        <dirname>1025</dirname>
        <basename>TELA-poligonos_e_circunferencia---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1032</id_resource>
        <id_file>5710</id_file>
        <type>2</type>
        <dirname>1032</dirname>
        <basename>TELA-quanto_voce_tem_de_pele---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1361</id_resource>
        <id_file>7000</id_file>
        <type>2</type>
        <dirname>1361</dirname>
        <basename>TELA-torres_de_hanoi---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>995</id_resource>
        <id_file>16324</id_file>
        <type>2</type>
        <dirname>995</dirname>
        <basename>TELA-a_matematica_dos_calendarios---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>996</id_resource>
        <id_file>16330</id_file>
        <type>2</type>
        <dirname>996</dirname>
        <basename>TELA-arco_capaz_e_navegacao---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>997</id_resource>
        <id_file>16336</id_file>
        <type>2</type>
        <dirname>997</dirname>
        <basename>TELA-baralho_e_torradas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>998</id_resource>
        <id_file>16342</id_file>
        <type>2</type>
        <dirname>998</dirname>
        <basename>TELA-baralho_magico---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>999</id_resource>
        <id_file>16348</id_file>
        <type>2</type>
        <dirname>999</dirname>
        <basename>TELA-camara_escura---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1000</id_resource>
        <id_file>16354</id_file>
        <type>2</type>
        <dirname>1000</dirname>
        <basename>TELA-cilindro=cone+esfera2---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1001</id_resource>
        <id_file>16360</id_file>
        <type>2</type>
        <dirname>1001</dirname>
        <basename>TELA-com_quantas_cores_posso_pintar_um_mapa---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1002</id_resource>
        <id_file>16366</id_file>
        <type>2</type>
        <dirname>1002</dirname>
        <basename>TELA-como_economizar_cadarco---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1003</id_resource>
        <id_file>16372</id_file>
        <type>2</type>
        <dirname>1003</dirname>
        <basename>TELA-corrida_ao_100---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1004</id_resource>
        <id_file>16378</id_file>
        <type>2</type>
        <dirname>1004</dirname>
        <basename>TELA-curvas_de_nivel---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1005</id_resource>
        <id_file>16384</id_file>
        <type>2</type>
        <dirname>1005</dirname>
        <basename>TELA-de_quantas_maneiras_posso_passar_meu_cadarco---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1006</id_resource>
        <id_file>16390</id_file>
        <type>2</type>
        <dirname>1006</dirname>
        <basename>TELA-dinamometro_com_elastico---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1008</id_resource>
        <id_file>16402</id_file>
        <type>2</type>
        <dirname>1008</dirname>
        <basename>TELA-eliminando_quadrados---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1009</id_resource>
        <id_file>16408</id_file>
        <type>2</type>
        <dirname>1009</dirname>
        <basename>TELA-empacotamento_de_latas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1010</id_resource>
        <id_file>16414</id_file>
        <type>2</type>
        <dirname>1010</dirname>
        <basename>TELA-engenharia_de_grego---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1011</id_resource>
        <id_file>16420</id_file>
        <type>2</type>
        <dirname>1011</dirname>
        <basename>TELA-escoamento_de_areia---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1012</id_resource>
        <id_file>16426</id_file>
        <type>2</type>
        <dirname>1012</dirname>
        <basename>TELA-espelhos_e_simetrias---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1013</id_resource>
        <id_file>16432</id_file>
        <type>2</type>
        <dirname>1013</dirname>
        <basename>TELA-esqueletos_no_espaco---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1014</id_resource>
        <id_file>16438</id_file>
        <type>2</type>
        <dirname>1014</dirname>
        <basename>TELA-estatuas_e_dominos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1015</id_resource>
        <id_file>16444</id_file>
        <type>2</type>
        <dirname>1015</dirname>
        <basename>TELA-estradas_para_estacao---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1016</id_resource>
        <id_file>16450</id_file>
        <type>2</type>
        <dirname>1016</dirname>
        <basename>TELA-jankenpon---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1017</id_resource>
        <id_file>16456</id_file>
        <type>2</type>
        <dirname>1017</dirname>
        <basename>TELA-jogo_das_amebas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1018</id_resource>
        <id_file>16462</id_file>
        <type>2</type>
        <dirname>1018</dirname>
        <basename>TELA-jogo_dos_divisores---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1019</id_resource>
        <id_file>16468</id_file>
        <type>2</type>
        <dirname>1019</dirname>
        <basename>TELA-magica_das_cartelas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1020</id_resource>
        <id_file>16474</id_file>
        <type>2</type>
        <dirname>1020</dirname>
        <basename>TELA-mensagens_secretas_com_matrizes---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1021</id_resource>
        <id_file>16480</id_file>
        <type>2</type>
        <dirname>1021</dirname>
        <basename>TELA-montanhas_geometricas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1022</id_resource>
        <id_file>16486</id_file>
        <type>2</type>
        <dirname>1022</dirname>
        <basename>TELA-morto_ou_vivo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1023</id_resource>
        <id_file>16492</id_file>
        <type>2</type>
        <dirname>1023</dirname>
        <basename>TELA-o_quadrado_de_koch---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1024</id_resource>
        <id_file>16498</id_file>
        <type>2</type>
        <dirname>1024</dirname>
        <basename>TELA-onde_fica_a_lixeira---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1026</id_resource>
        <id_file>16510</id_file>
        <type>2</type>
        <dirname>1026</dirname>
        <basename>TELA-poligonos_regulares_e_ladrilhos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1027</id_resource>
        <id_file>16516</id_file>
        <type>2</type>
        <dirname>1027</dirname>
        <basename>TELA-populacao_versus_amostra---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1028</id_resource>
        <id_file>16522</id_file>
        <type>2</type>
        <dirname>1028</dirname>
        <basename>TELA-quadrado_magico_aditivo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1029</id_resource>
        <id_file>16528</id_file>
        <type>2</type>
        <dirname>1029</dirname>
        <basename>TELA-qual_e_a_area_do_quadrilatero---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1030</id_resource>
        <id_file>16534</id_file>
        <type>2</type>
        <dirname>1030</dirname>
        <basename>TELA-qual_e_o_cone_com_maior_volume---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1031</id_resource>
        <id_file>16540</id_file>
        <type>2</type>
        <dirname>1031</dirname>
        <basename>TELA-qual_e_o_prisma_com_maior_volume---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1034</id_resource>
        <id_file>16553</id_file>
        <type>2</type>
        <dirname>1034</dirname>
        <basename>TELA-series_temporais---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1035</id_resource>
        <id_file>16559</id_file>
        <type>2</type>
        <dirname>1035</dirname>
        <basename>TELA-taxi_e_combinatoria---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1037</id_resource>
        <id_file>16571</id_file>
        <type>2</type>
        <dirname>1037</dirname>
        <basename>TELA-transformacao_de_mobius---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1038</id_resource>
        <id_file>16577</id_file>
        <type>2</type>
        <dirname>1038</dirname>
        <basename>TELA-variaveis_antropometricas---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1039</id_resource>
        <id_file>16583</id_file>
        <type>2</type>
        <dirname>1039</dirname>
        <basename>TELA-volume_de_piramides---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>994</id_resource>
        <id_file>17610</id_file>
        <type>2</type>
        <dirname>994</dirname>
        <basename>TELA-a_altura_da_arvore---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1033</id_resource>
        <id_file>17616</id_file>
        <type>2</type>
        <dirname>1033</dirname>
        <basename>TELA-a_roda-gigante---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1365</id_resource>
        <id_file>17628</id_file>
        <type>2</type>
        <dirname>1365</dirname>
        <basename>TELA-apostas_no_relogio---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1366</id_resource>
        <id_file>17634</id_file>
        <type>2</type>
        <dirname>1366</dirname>
        <basename>TELA-avalanches---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1367</id_resource>
        <id_file>17640</id_file>
        <type>2</type>
        <dirname>1367</dirname>
        <basename>TELA-caixa_de_papel---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1368</id_resource>
        <id_file>17646</id_file>
        <type>2</type>
        <dirname>1368</dirname>
        <basename>TELA-caminhos_e_grafos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1369</id_resource>
        <id_file>17652</id_file>
        <type>2</type>
        <dirname>1369</dirname>
        <basename>TELA-cortar_cubos---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1370</id_resource>
        <id_file>17658</id_file>
        <type>2</type>
        <dirname>1370</dirname>
        <basename>TELA-dobra_a_lingua_e_coca_a_orelha---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1371</id_resource>
        <id_file>17664</id_file>
        <type>2</type>
        <dirname>1371</dirname>
        <basename>TELA-o_metodo_de_monte_carlo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1372</id_resource>
        <id_file>17670</id_file>
        <type>2</type>
        <dirname>1372</dirname>
        <basename>TELA-quadrado_magico_multiplicativo---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1373</id_resource>
        <id_file>17676</id_file>
        <type>2</type>
        <dirname>1373</dirname>
        <basename>TELA-quantos_peixes_ha_no_lago---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1374</id_resource>
        <id_file>17682</id_file>
        <type>2</type>
        <dirname>1374</dirname>
        <basename>TELA-que_curva_e_essa_chamada_elipse---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1380</id_resource>
        <id_file>17717</id_file>
        <type>2</type>
        <dirname>1380</dirname>
        <basename>TELA-jogo_da_trilha---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1386</id_resource>
        <id_file>17747</id_file>
        <type>2</type>
        <dirname>1386</dirname>
        <basename>TELA-padroes_no_plano---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1036</id_resource>
        <id_file>17973</id_file>
        <type>2</type>
        <dirname>1036</dirname>
        <basename>TELA-otimizacao_da_cerca---o_experimento.pdf</basename>
    </file>
    <file>
        <id_resource>1007</id_resource>
        <id_file>5561</id_file>
        <type>3</type>
        <dirname>1007</dirname>
        <basename>duplicacao_do_cubo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1025</id_resource>
        <id_file>5669</id_file>
        <type>3</type>
        <dirname>1025</dirname>
        <basename>poligonos_e_circunferencia---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1032</id_resource>
        <id_file>5711</id_file>
        <type>3</type>
        <dirname>1032</dirname>
        <basename>quanto_voce_tem_de_pele---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1361</id_resource>
        <id_file>7001</id_file>
        <type>3</type>
        <dirname>1361</dirname>
        <basename>torres_de_hanoi---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>995</id_resource>
        <id_file>16325</id_file>
        <type>3</type>
        <dirname>995</dirname>
        <basename>a_matematica_dos_calendarios---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>996</id_resource>
        <id_file>16331</id_file>
        <type>3</type>
        <dirname>996</dirname>
        <basename>arco_capaz_e_navegacao---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>997</id_resource>
        <id_file>16337</id_file>
        <type>3</type>
        <dirname>997</dirname>
        <basename>baralho_e_torradas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>998</id_resource>
        <id_file>16343</id_file>
        <type>3</type>
        <dirname>998</dirname>
        <basename>baralho_magico---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>999</id_resource>
        <id_file>16349</id_file>
        <type>3</type>
        <dirname>999</dirname>
        <basename>camara_escura---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1000</id_resource>
        <id_file>16355</id_file>
        <type>3</type>
        <dirname>1000</dirname>
        <basename>cilindro=cone+esfera2---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1001</id_resource>
        <id_file>16361</id_file>
        <type>3</type>
        <dirname>1001</dirname>
        <basename>com_quantas_cores_posso_pintar_um_mapa---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1002</id_resource>
        <id_file>16367</id_file>
        <type>3</type>
        <dirname>1002</dirname>
        <basename>como_economizar_cadarco---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1003</id_resource>
        <id_file>16373</id_file>
        <type>3</type>
        <dirname>1003</dirname>
        <basename>corrida_ao_100---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1004</id_resource>
        <id_file>16379</id_file>
        <type>3</type>
        <dirname>1004</dirname>
        <basename>curvas_de_nivel---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1005</id_resource>
        <id_file>16385</id_file>
        <type>3</type>
        <dirname>1005</dirname>
        <basename>de_quantas_maneiras_posso_passar_meu_cadarco---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1006</id_resource>
        <id_file>16391</id_file>
        <type>3</type>
        <dirname>1006</dirname>
        <basename>dinamometro_com_elastico---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1008</id_resource>
        <id_file>16403</id_file>
        <type>3</type>
        <dirname>1008</dirname>
        <basename>eliminando_quadrados---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1009</id_resource>
        <id_file>16409</id_file>
        <type>3</type>
        <dirname>1009</dirname>
        <basename>empacotamento_de_latas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1010</id_resource>
        <id_file>16415</id_file>
        <type>3</type>
        <dirname>1010</dirname>
        <basename>engenharia_de_grego---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1011</id_resource>
        <id_file>16421</id_file>
        <type>3</type>
        <dirname>1011</dirname>
        <basename>escoamento_de_areia---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1012</id_resource>
        <id_file>16427</id_file>
        <type>3</type>
        <dirname>1012</dirname>
        <basename>espelhos_e_simetrias---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1013</id_resource>
        <id_file>16433</id_file>
        <type>3</type>
        <dirname>1013</dirname>
        <basename>esqueletos_no_espaco---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1014</id_resource>
        <id_file>16439</id_file>
        <type>3</type>
        <dirname>1014</dirname>
        <basename>estatuas_e_dominos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1015</id_resource>
        <id_file>16445</id_file>
        <type>3</type>
        <dirname>1015</dirname>
        <basename>estradas_para_estacao---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1016</id_resource>
        <id_file>16451</id_file>
        <type>3</type>
        <dirname>1016</dirname>
        <basename>jankenpon---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1017</id_resource>
        <id_file>16457</id_file>
        <type>3</type>
        <dirname>1017</dirname>
        <basename>jogo_das_amebas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1018</id_resource>
        <id_file>16463</id_file>
        <type>3</type>
        <dirname>1018</dirname>
        <basename>jogo_dos_divisores---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1019</id_resource>
        <id_file>16469</id_file>
        <type>3</type>
        <dirname>1019</dirname>
        <basename>magica_das_cartelas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1020</id_resource>
        <id_file>16475</id_file>
        <type>3</type>
        <dirname>1020</dirname>
        <basename>mensagens_secretas_com_matrizes---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1021</id_resource>
        <id_file>16481</id_file>
        <type>3</type>
        <dirname>1021</dirname>
        <basename>montanhas_geometricas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1022</id_resource>
        <id_file>16487</id_file>
        <type>3</type>
        <dirname>1022</dirname>
        <basename>morto_ou_vivo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1023</id_resource>
        <id_file>16493</id_file>
        <type>3</type>
        <dirname>1023</dirname>
        <basename>o_quadrado_de_koch---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1024</id_resource>
        <id_file>16499</id_file>
        <type>3</type>
        <dirname>1024</dirname>
        <basename>onde_fica_a_lixeira---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1026</id_resource>
        <id_file>16511</id_file>
        <type>3</type>
        <dirname>1026</dirname>
        <basename>poligonos_regulares_e_ladrilhos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1027</id_resource>
        <id_file>16517</id_file>
        <type>3</type>
        <dirname>1027</dirname>
        <basename>populacao_versus_amostra---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1028</id_resource>
        <id_file>16523</id_file>
        <type>3</type>
        <dirname>1028</dirname>
        <basename>quadrado_magico_aditivo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1029</id_resource>
        <id_file>16529</id_file>
        <type>3</type>
        <dirname>1029</dirname>
        <basename>qual_e_a_area_do_quadrilatero---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1030</id_resource>
        <id_file>16535</id_file>
        <type>3</type>
        <dirname>1030</dirname>
        <basename>qual_e_o_cone_com_maior_volume---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1031</id_resource>
        <id_file>16541</id_file>
        <type>3</type>
        <dirname>1031</dirname>
        <basename>qual_e_o_prisma_com_maior_volume---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1034</id_resource>
        <id_file>16554</id_file>
        <type>3</type>
        <dirname>1034</dirname>
        <basename>series_temporais---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1035</id_resource>
        <id_file>16560</id_file>
        <type>3</type>
        <dirname>1035</dirname>
        <basename>taxi_e_combinatoria---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1037</id_resource>
        <id_file>16572</id_file>
        <type>3</type>
        <dirname>1037</dirname>
        <basename>transformacao_de_mobius---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1038</id_resource>
        <id_file>16578</id_file>
        <type>3</type>
        <dirname>1038</dirname>
        <basename>variaveis_antropometricas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1039</id_resource>
        <id_file>16584</id_file>
        <type>3</type>
        <dirname>1039</dirname>
        <basename>volume_de_piramides---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>994</id_resource>
        <id_file>17611</id_file>
        <type>3</type>
        <dirname>994</dirname>
        <basename>a_altura_da_arvore---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1033</id_resource>
        <id_file>17617</id_file>
        <type>3</type>
        <dirname>1033</dirname>
        <basename>a_roda-gigante---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1365</id_resource>
        <id_file>17629</id_file>
        <type>3</type>
        <dirname>1365</dirname>
        <basename>apostas_no_relogio---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1366</id_resource>
        <id_file>17635</id_file>
        <type>3</type>
        <dirname>1366</dirname>
        <basename>avalanches---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1367</id_resource>
        <id_file>17641</id_file>
        <type>3</type>
        <dirname>1367</dirname>
        <basename>caixa_de_papel---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1368</id_resource>
        <id_file>17647</id_file>
        <type>3</type>
        <dirname>1368</dirname>
        <basename>caminhos_e_grafos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1369</id_resource>
        <id_file>17653</id_file>
        <type>3</type>
        <dirname>1369</dirname>
        <basename>cortar_cubos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1370</id_resource>
        <id_file>17659</id_file>
        <type>3</type>
        <dirname>1370</dirname>
        <basename>dobra_a_lingua_e_coca_a_orelha---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1371</id_resource>
        <id_file>17665</id_file>
        <type>3</type>
        <dirname>1371</dirname>
        <basename>o_metodo_de_monte_carlo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1372</id_resource>
        <id_file>17671</id_file>
        <type>3</type>
        <dirname>1372</dirname>
        <basename>quadrado_magico_multiplicativo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1373</id_resource>
        <id_file>17677</id_file>
        <type>3</type>
        <dirname>1373</dirname>
        <basename>quantos_peixes_ha_no_lago---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1374</id_resource>
        <id_file>17683</id_file>
        <type>3</type>
        <dirname>1374</dirname>
        <basename>que_curva_e_essa_chamada_elipse---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1380</id_resource>
        <id_file>17718</id_file>
        <type>3</type>
        <dirname>1380</dirname>
        <basename>jogo_da_trilha---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1386</id_resource>
        <id_file>17748</id_file>
        <type>3</type>
        <dirname>1386</dirname>
        <basename>padroes_no_plano---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1036</id_resource>
        <id_file>17974</id_file>
        <type>3</type>
        <dirname>1036</dirname>
        <basename>otimizacao_da_cerca---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1363</id_resource>
        <id_file>18092</id_file>
        <type>3</type>
        <dirname>1363</dirname>
        <basename>guia.pdf</basename>
    </file>
    <file>
        <id_resource>1225</id_resource>
        <id_file>18152</id_file>
        <type>3</type>
        <dirname>1225</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1244</id_resource>
        <id_file>18169</id_file>
        <type>3</type>
        <dirname>1244</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1376</id_resource>
        <id_file>18198</id_file>
        <type>3</type>
        <dirname>1376</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1250</id_resource>
        <id_file>18207</id_file>
        <type>3</type>
        <dirname>1250</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1249</id_resource>
        <id_file>18228</id_file>
        <type>3</type>
        <dirname>1249</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1240</id_resource>
        <id_file>18231</id_file>
        <type>3</type>
        <dirname>1240</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1243</id_resource>
        <id_file>18234</id_file>
        <type>3</type>
        <dirname>1243</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1237</id_resource>
        <id_file>18243</id_file>
        <type>3</type>
        <dirname>1237</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1227</id_resource>
        <id_file>18256</id_file>
        <type>3</type>
        <dirname>1227</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1239</id_resource>
        <id_file>18264</id_file>
        <type>3</type>
        <dirname>1239</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1224</id_resource>
        <id_file>18269</id_file>
        <type>3</type>
        <dirname>1224</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1220</id_resource>
        <id_file>18277</id_file>
        <type>3</type>
        <dirname>1220</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1221</id_resource>
        <id_file>18285</id_file>
        <type>3</type>
        <dirname>1221</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1232</id_resource>
        <id_file>18296</id_file>
        <type>3</type>
        <dirname>1232</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1238</id_resource>
        <id_file>18299</id_file>
        <type>3</type>
        <dirname>1238</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1233</id_resource>
        <id_file>18302</id_file>
        <type>3</type>
        <dirname>1233</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1226</id_resource>
        <id_file>18309</id_file>
        <type>3</type>
        <dirname>1226</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1228</id_resource>
        <id_file>18360</id_file>
        <type>3</type>
        <dirname>1228</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1236</id_resource>
        <id_file>18368</id_file>
        <type>3</type>
        <dirname>1236</dirname>
        <basename>impressao.pdf</basename>
    </file>
    <file>
        <id_resource>1007</id_resource>
        <id_file>5562</id_file>
        <type>4</type>
        <dirname>1007</dirname>
        <basename>TELA-duplicacao_do_cubo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1025</id_resource>
        <id_file>5670</id_file>
        <type>4</type>
        <dirname>1025</dirname>
        <basename>TELA-poligonos_e_circunferencia---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1032</id_resource>
        <id_file>5712</id_file>
        <type>4</type>
        <dirname>1032</dirname>
        <basename>TELA-quanto_voce_tem_de_pele---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1361</id_resource>
        <id_file>7002</id_file>
        <type>4</type>
        <dirname>1361</dirname>
        <basename>TELA-torres_de_hanoi---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>995</id_resource>
        <id_file>16326</id_file>
        <type>4</type>
        <dirname>995</dirname>
        <basename>TELA-a_matematica_dos_calendarios---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>996</id_resource>
        <id_file>16332</id_file>
        <type>4</type>
        <dirname>996</dirname>
        <basename>TELA-arco_capaz_e_navegacao---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>997</id_resource>
        <id_file>16338</id_file>
        <type>4</type>
        <dirname>997</dirname>
        <basename>TELA-baralho_e_torradas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>998</id_resource>
        <id_file>16344</id_file>
        <type>4</type>
        <dirname>998</dirname>
        <basename>TELA-baralho_magico---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>999</id_resource>
        <id_file>16350</id_file>
        <type>4</type>
        <dirname>999</dirname>
        <basename>TELA-camara_escura---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1000</id_resource>
        <id_file>16356</id_file>
        <type>4</type>
        <dirname>1000</dirname>
        <basename>TELA-cilindro=cone+esfera2---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1001</id_resource>
        <id_file>16362</id_file>
        <type>4</type>
        <dirname>1001</dirname>
        <basename>TELA-com_quantas_cores_posso_pintar_um_mapa---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1002</id_resource>
        <id_file>16368</id_file>
        <type>4</type>
        <dirname>1002</dirname>
        <basename>TELA-como_economizar_cadarco---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1003</id_resource>
        <id_file>16374</id_file>
        <type>4</type>
        <dirname>1003</dirname>
        <basename>TELA-corrida_ao_100---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1004</id_resource>
        <id_file>16380</id_file>
        <type>4</type>
        <dirname>1004</dirname>
        <basename>TELA-curvas_de_nivel---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1005</id_resource>
        <id_file>16386</id_file>
        <type>4</type>
        <dirname>1005</dirname>
        <basename>TELA-de_quantas_maneiras_posso_passar_meu_cadarco---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1006</id_resource>
        <id_file>16392</id_file>
        <type>4</type>
        <dirname>1006</dirname>
        <basename>TELA-dinamometro_com_elastico---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1008</id_resource>
        <id_file>16404</id_file>
        <type>4</type>
        <dirname>1008</dirname>
        <basename>TELA-eliminando_quadrados---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1009</id_resource>
        <id_file>16410</id_file>
        <type>4</type>
        <dirname>1009</dirname>
        <basename>TELA-empacotamento_de_latas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1010</id_resource>
        <id_file>16416</id_file>
        <type>4</type>
        <dirname>1010</dirname>
        <basename>TELA-engenharia_de_grego---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1011</id_resource>
        <id_file>16422</id_file>
        <type>4</type>
        <dirname>1011</dirname>
        <basename>TELA-escoamento_de_areia---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1012</id_resource>
        <id_file>16428</id_file>
        <type>4</type>
        <dirname>1012</dirname>
        <basename>TELA-espelhos_e_simetrias---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1013</id_resource>
        <id_file>16434</id_file>
        <type>4</type>
        <dirname>1013</dirname>
        <basename>TELA-esqueletos_no_espaco---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1014</id_resource>
        <id_file>16440</id_file>
        <type>4</type>
        <dirname>1014</dirname>
        <basename>TELA-estatuas_e_dominos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1015</id_resource>
        <id_file>16446</id_file>
        <type>4</type>
        <dirname>1015</dirname>
        <basename>TELA-estradas_para_estacao---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1016</id_resource>
        <id_file>16452</id_file>
        <type>4</type>
        <dirname>1016</dirname>
        <basename>TELA-jankenpon---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1017</id_resource>
        <id_file>16458</id_file>
        <type>4</type>
        <dirname>1017</dirname>
        <basename>TELA-jogo_das_amebas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1018</id_resource>
        <id_file>16464</id_file>
        <type>4</type>
        <dirname>1018</dirname>
        <basename>TELA-jogo_dos_divisores---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1019</id_resource>
        <id_file>16470</id_file>
        <type>4</type>
        <dirname>1019</dirname>
        <basename>TELA-magica_das_cartelas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1020</id_resource>
        <id_file>16476</id_file>
        <type>4</type>
        <dirname>1020</dirname>
        <basename>TELA-mensagens_secretas_com_matrizes---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1021</id_resource>
        <id_file>16482</id_file>
        <type>4</type>
        <dirname>1021</dirname>
        <basename>TELA-montanhas_geometricas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1022</id_resource>
        <id_file>16488</id_file>
        <type>4</type>
        <dirname>1022</dirname>
        <basename>TELA-morto_ou_vivo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1023</id_resource>
        <id_file>16494</id_file>
        <type>4</type>
        <dirname>1023</dirname>
        <basename>TELA-o_quadrado_de_koch---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1024</id_resource>
        <id_file>16500</id_file>
        <type>4</type>
        <dirname>1024</dirname>
        <basename>TELA-onde_fica_a_lixeira---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1026</id_resource>
        <id_file>16512</id_file>
        <type>4</type>
        <dirname>1026</dirname>
        <basename>TELA-poligonos_regulares_e_ladrilhos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1027</id_resource>
        <id_file>16518</id_file>
        <type>4</type>
        <dirname>1027</dirname>
        <basename>TELA-populacao_versus_amostra---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1028</id_resource>
        <id_file>16524</id_file>
        <type>4</type>
        <dirname>1028</dirname>
        <basename>TELA-quadrado_magico_aditivo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1029</id_resource>
        <id_file>16530</id_file>
        <type>4</type>
        <dirname>1029</dirname>
        <basename>TELA-qual_e_a_area_do_quadrilatero---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1030</id_resource>
        <id_file>16536</id_file>
        <type>4</type>
        <dirname>1030</dirname>
        <basename>TELA-qual_e_o_cone_com_maior_volume---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1031</id_resource>
        <id_file>16542</id_file>
        <type>4</type>
        <dirname>1031</dirname>
        <basename>TELA-qual_e_o_prisma_com_maior_volume---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1034</id_resource>
        <id_file>16555</id_file>
        <type>4</type>
        <dirname>1034</dirname>
        <basename>TELA-series_temporais---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1035</id_resource>
        <id_file>16561</id_file>
        <type>4</type>
        <dirname>1035</dirname>
        <basename>TELA-taxi_e_combinatoria---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1037</id_resource>
        <id_file>16573</id_file>
        <type>4</type>
        <dirname>1037</dirname>
        <basename>TELA-transformacao_de_mobius---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1038</id_resource>
        <id_file>16579</id_file>
        <type>4</type>
        <dirname>1038</dirname>
        <basename>TELA-variaveis_antropometricas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1039</id_resource>
        <id_file>16585</id_file>
        <type>4</type>
        <dirname>1039</dirname>
        <basename>TELA-volume_de_piramides---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>994</id_resource>
        <id_file>17612</id_file>
        <type>4</type>
        <dirname>994</dirname>
        <basename>TELA-a_altura_da_arvore---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1033</id_resource>
        <id_file>17618</id_file>
        <type>4</type>
        <dirname>1033</dirname>
        <basename>TELA-a_roda-gigante---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1365</id_resource>
        <id_file>17630</id_file>
        <type>4</type>
        <dirname>1365</dirname>
        <basename>TELA-apostas_no_relogio---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1366</id_resource>
        <id_file>17636</id_file>
        <type>4</type>
        <dirname>1366</dirname>
        <basename>TELA-avalanches---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1367</id_resource>
        <id_file>17642</id_file>
        <type>4</type>
        <dirname>1367</dirname>
        <basename>TELA-caixa_de_papel---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1368</id_resource>
        <id_file>17648</id_file>
        <type>4</type>
        <dirname>1368</dirname>
        <basename>TELA-caminhos_e_grafos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1369</id_resource>
        <id_file>17654</id_file>
        <type>4</type>
        <dirname>1369</dirname>
        <basename>TELA-cortar_cubos---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1370</id_resource>
        <id_file>17660</id_file>
        <type>4</type>
        <dirname>1370</dirname>
        <basename>TELA-dobra_a_lingua_e_coca_a_orelha---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1371</id_resource>
        <id_file>17666</id_file>
        <type>4</type>
        <dirname>1371</dirname>
        <basename>TELA-o_metodo_de_monte_carlo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1372</id_resource>
        <id_file>17672</id_file>
        <type>4</type>
        <dirname>1372</dirname>
        <basename>TELA-quadrado_magico_multiplicativo---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1373</id_resource>
        <id_file>17678</id_file>
        <type>4</type>
        <dirname>1373</dirname>
        <basename>TELA-quantos_peixes_ha_no_lago---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1374</id_resource>
        <id_file>17684</id_file>
        <type>4</type>
        <dirname>1374</dirname>
        <basename>TELA-que_curva_e_essa_chamada_elipse---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1380</id_resource>
        <id_file>17719</id_file>
        <type>4</type>
        <dirname>1380</dirname>
        <basename>TELA-jogo_da_trilha---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1386</id_resource>
        <id_file>17749</id_file>
        <type>4</type>
        <dirname>1386</dirname>
        <basename>TELA-padroes_no_plano---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1036</id_resource>
        <id_file>17975</id_file>
        <type>4</type>
        <dirname>1036</dirname>
        <basename>TELA-otimizacao_da_cerca---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1363</id_resource>
        <id_file>18093</id_file>
        <type>4</type>
        <dirname>1363</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1225</id_resource>
        <id_file>18153</id_file>
        <type>4</type>
        <dirname>1225</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1244</id_resource>
        <id_file>18170</id_file>
        <type>4</type>
        <dirname>1244</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1376</id_resource>
        <id_file>18199</id_file>
        <type>4</type>
        <dirname>1376</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1250</id_resource>
        <id_file>18208</id_file>
        <type>4</type>
        <dirname>1250</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1249</id_resource>
        <id_file>18229</id_file>
        <type>4</type>
        <dirname>1249</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1240</id_resource>
        <id_file>18232</id_file>
        <type>4</type>
        <dirname>1240</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1243</id_resource>
        <id_file>18235</id_file>
        <type>4</type>
        <dirname>1243</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1237</id_resource>
        <id_file>18244</id_file>
        <type>4</type>
        <dirname>1237</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1227</id_resource>
        <id_file>18257</id_file>
        <type>4</type>
        <dirname>1227</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1239</id_resource>
        <id_file>18265</id_file>
        <type>4</type>
        <dirname>1239</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1224</id_resource>
        <id_file>18270</id_file>
        <type>4</type>
        <dirname>1224</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1220</id_resource>
        <id_file>18278</id_file>
        <type>4</type>
        <dirname>1220</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1221</id_resource>
        <id_file>18286</id_file>
        <type>4</type>
        <dirname>1221</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1232</id_resource>
        <id_file>18297</id_file>
        <type>4</type>
        <dirname>1232</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1238</id_resource>
        <id_file>18300</id_file>
        <type>4</type>
        <dirname>1238</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1233</id_resource>
        <id_file>18303</id_file>
        <type>4</type>
        <dirname>1233</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1226</id_resource>
        <id_file>18310</id_file>
        <type>4</type>
        <dirname>1226</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1228</id_resource>
        <id_file>18361</id_file>
        <type>4</type>
        <dirname>1228</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1236</id_resource>
        <id_file>18369</id_file>
        <type>4</type>
        <dirname>1236</dirname>
        <basename>tela.pdf</basename>
    </file>
    <file>
        <id_resource>1007</id_resource>
        <id_file>5563</id_file>
        <type>5</type>
        <dirname>1007</dirname>
        <basename>duplicacao_do_cubo---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1025</id_resource>
        <id_file>5671</id_file>
        <type>5</type>
        <dirname>1025</dirname>
        <basename>poligonos_e_circunferencia---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1032</id_resource>
        <id_file>5713</id_file>
        <type>5</type>
        <dirname>1032</dirname>
        <basename>quanto_voce_tem_de_pele---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1361</id_resource>
        <id_file>7003</id_file>
        <type>5</type>
        <dirname>1361</dirname>
        <basename>torres_de_hanoi---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>995</id_resource>
        <id_file>16327</id_file>
        <type>5</type>
        <dirname>995</dirname>
        <basename>a_matematica_dos_calendarios---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>996</id_resource>
        <id_file>16333</id_file>
        <type>5</type>
        <dirname>996</dirname>
        <basename>arco_capaz_e_navegacao---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>997</id_resource>
        <id_file>16339</id_file>
        <type>5</type>
        <dirname>997</dirname>
        <basename>baralho_e_torradas---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>998</id_resource>
        <id_file>16345</id_file>
        <type>5</type>
        <dirname>998</dirname>
        <basename>baralho_magico---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>999</id_resource>
        <id_file>16351</id_file>
        <type>5</type>
        <dirname>999</dirname>
        <basename>camara_escura---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1000</id_resource>
        <id_file>16357</id_file>
        <type>5</type>
        <dirname>1000</dirname>
        <basename>cilindro=cone+esfera2---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1001</id_resource>
        <id_file>16363</id_file>
        <type>5</type>
        <dirname>1001</dirname>
        <basename>com_quantas_cores_posso_pintar_um_mapa---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1002</id_resource>
        <id_file>16369</id_file>
        <type>5</type>
        <dirname>1002</dirname>
        <basename>como_economizar_cadarco---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1003</id_resource>
        <id_file>16375</id_file>
        <type>5</type>
        <dirname>1003</dirname>
        <basename>corrida_ao_100---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1004</id_resource>
        <id_file>16381</id_file>
        <type>5</type>
        <dirname>1004</dirname>
        <basename>curvas_de_nivel---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1005</id_resource>
        <id_file>16387</id_file>
        <type>5</type>
        <dirname>1005</dirname>
        <basename>de_quantas_maneiras_posso_passar_meu_cadarco---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1006</id_resource>
        <id_file>16393</id_file>
        <type>5</type>
        <dirname>1006</dirname>
        <basename>dinamometro_com_elastico---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1008</id_resource>
        <id_file>16405</id_file>
        <type>5</type>
        <dirname>1008</dirname>
        <basename>eliminando_quadrados---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1009</id_resource>
        <id_file>16411</id_file>
        <type>5</type>
        <dirname>1009</dirname>
        <basename>empacotamento_de_latas---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1010</id_resource>
        <id_file>16417</id_file>
        <type>5</type>
        <dirname>1010</dirname>
        <basename>engenharia_de_grego---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1011</id_resource>
        <id_file>16423</id_file>
        <type>5</type>
        <dirname>1011</dirname>
        <basename>escoamento_de_areia---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1012</id_resource>
        <id_file>16429</id_file>
        <type>5</type>
        <dirname>1012</dirname>
        <basename>espelhos_e_simetrias---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1013</id_resource>
        <id_file>16435</id_file>
        <type>5</type>
        <dirname>1013</dirname>
        <basename>esqueletos_no_espaco---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1014</id_resource>
        <id_file>16441</id_file>
        <type>5</type>
        <dirname>1014</dirname>
        <basename>estatuas_e_dominos---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1015</id_resource>
        <id_file>16447</id_file>
        <type>5</type>
        <dirname>1015</dirname>
        <basename>estradas_para_estacao---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1016</id_resource>
        <id_file>16453</id_file>
        <type>5</type>
        <dirname>1016</dirname>
        <basename>jankenpon---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1017</id_resource>
        <id_file>16459</id_file>
        <type>5</type>
        <dirname>1017</dirname>
        <basename>jogo_das_amebas---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1018</id_resource>
        <id_file>16465</id_file>
        <type>5</type>
        <dirname>1018</dirname>
        <basename>jogo_dos_divisores---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1019</id_resource>
        <id_file>16471</id_file>
        <type>5</type>
        <dirname>1019</dirname>
        <basename>magica_das_cartelas---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1020</id_resource>
        <id_file>16477</id_file>
        <type>5</type>
        <dirname>1020</dirname>
        <basename>mensagens_secretas_com_matrizes---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1021</id_resource>
        <id_file>16483</id_file>
        <type>5</type>
        <dirname>1021</dirname>
        <basename>montanhas_geometricas---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1022</id_resource>
        <id_file>16489</id_file>
        <type>5</type>
        <dirname>1022</dirname>
        <basename>morto_ou_vivo---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1023</id_resource>
        <id_file>16495</id_file>
        <type>5</type>
        <dirname>1023</dirname>
        <basename>o_quadrado_de_koch---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1024</id_resource>
        <id_file>16501</id_file>
        <type>5</type>
        <dirname>1024</dirname>
        <basename>onde_fica_a_lixeira---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1026</id_resource>
        <id_file>16513</id_file>
        <type>5</type>
        <dirname>1026</dirname>
        <basename>poligonos_regulares_e_ladrilhos---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1027</id_resource>
        <id_file>16519</id_file>
        <type>5</type>
        <dirname>1027</dirname>
        <basename>populacao_versus_amostra---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1028</id_resource>
        <id_file>16525</id_file>
        <type>5</type>
        <dirname>1028</dirname>
        <basename>quadrado_magico_aditivo---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1029</id_resource>
        <id_file>16531</id_file>
        <type>5</type>
        <dirname>1029</dirname>
        <basename>qual_e_a_area_do_quadrilatero---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1030</id_resource>
        <id_file>16537</id_file>
        <type>5</type>
        <dirname>1030</dirname>
        <basename>qual_e_o_cone_com_maior_volume---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1031</id_resource>
        <id_file>16543</id_file>
        <type>5</type>
        <dirname>1031</dirname>
        <basename>qual_e_o_prisma_com_maior_volume---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1034</id_resource>
        <id_file>16556</id_file>
        <type>5</type>
        <dirname>1034</dirname>
        <basename>series_temporais---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1035</id_resource>
        <id_file>16562</id_file>
        <type>5</type>
        <dirname>1035</dirname>
        <basename>taxi_e_combinatoria---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1037</id_resource>
        <id_file>16574</id_file>
        <type>5</type>
        <dirname>1037</dirname>
        <basename>transformacao_de_mobius---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1038</id_resource>
        <id_file>16580</id_file>
        <type>5</type>
        <dirname>1038</dirname>
        <basename>variaveis_antropometricas---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1039</id_resource>
        <id_file>16586</id_file>
        <type>5</type>
        <dirname>1039</dirname>
        <basename>volume_de_piramides---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>994</id_resource>
        <id_file>17613</id_file>
        <type>5</type>
        <dirname>994</dirname>
        <basename>a_altura_da_arvore---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1033</id_resource>
        <id_file>17619</id_file>
        <type>5</type>
        <dirname>1033</dirname>
        <basename>a_roda-gigante---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1365</id_resource>
        <id_file>17631</id_file>
        <type>5</type>
        <dirname>1365</dirname>
        <basename>apostas_no_relogio---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1366</id_resource>
        <id_file>17637</id_file>
        <type>5</type>
        <dirname>1366</dirname>
        <basename>avalanches---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1367</id_resource>
        <id_file>17643</id_file>
        <type>5</type>
        <dirname>1367</dirname>
        <basename>caixa_de_papel---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1368</id_resource>
        <id_file>17649</id_file>
        <type>5</type>
        <dirname>1368</dirname>
        <basename>caminhos_e_grafos---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1369</id_resource>
        <id_file>17655</id_file>
        <type>5</type>
        <dirname>1369</dirname>
        <basename>cortar_cubos---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1370</id_resource>
        <id_file>17661</id_file>
        <type>5</type>
        <dirname>1370</dirname>
        <basename>dobra_a_lingua_e_coca_a_orelha---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1371</id_resource>
        <id_file>17667</id_file>
        <type>5</type>
        <dirname>1371</dirname>
        <basename>o_metodo_de_monte_carlo---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1372</id_resource>
        <id_file>17673</id_file>
        <type>5</type>
        <dirname>1372</dirname>
        <basename>quadrado_magico_multiplicativo---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1373</id_resource>
        <id_file>17679</id_file>
        <type>5</type>
        <dirname>1373</dirname>
        <basename>quantos_peixes_ha_no_lago---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1374</id_resource>
        <id_file>17685</id_file>
        <type>5</type>
        <dirname>1374</dirname>
        <basename>que_curva_e_essa_chamada_elipse---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1380</id_resource>
        <id_file>17720</id_file>
        <type>5</type>
        <dirname>1380</dirname>
        <basename>jogo_da_trilha---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1386</id_resource>
        <id_file>17750</id_file>
        <type>5</type>
        <dirname>1386</dirname>
        <basename>padroes_no_plano---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1036</id_resource>
        <id_file>17976</id_file>
        <type>5</type>
        <dirname>1036</dirname>
        <basename>otimizacao_da_cerca---folha_do_aluno.pdf</basename>
    </file>
    <file>
        <id_resource>1040</id_resource>
        <id_file>16588</id_file>
        <type>6</type>
        <dirname>1040</dirname>
        <basename>3 2 1 - Misterio.mpg</basename>
    </file>
    <file>
        <id_resource>1041</id_resource>
        <id_file>16591</id_file>
        <type>6</type>
        <dirname>1041</dirname>
        <basename>078. A Espera da Meia-Noite.mpg</basename>
    </file>
    <file>
        <id_resource>1042</id_resource>
        <id_file>16594</id_file>
        <type>6</type>
        <dirname>1042</dirname>
        <basename>149. Abelhas Matematicas.mpg</basename>
    </file>
    <file>
        <id_resource>1043</id_resource>
        <id_file>16597</id_file>
        <type>6</type>
        <dirname>1043</dirname>
        <basename>038. Acao, Reacao e Correlacao.mpg</basename>
    </file>
    <file>
        <id_resource>1044</id_resource>
        <id_file>16600</id_file>
        <type>6</type>
        <dirname>1044</dirname>
        <basename>065. Eu Acho que Vi um Coelhinho.mpg</basename>
    </file>
    <file>
        <id_resource>1045</id_resource>
        <id_file>16603</id_file>
        <type>6</type>
        <dirname>1045</dirname>
        <basename>119. Afogando em Zeros.mpg</basename>
    </file>
    <file>
        <id_resource>1046</id_resource>
        <id_file>16606</id_file>
        <type>6</type>
        <dirname>1046</dirname>
        <basename>031. Alice e Algumas Relacoes Trigonometricas.mpg</basename>
    </file>
    <file>
        <id_resource>1047</id_resource>
        <id_file>16609</id_file>
        <type>6</type>
        <dirname>1047</dirname>
        <basename>014. Alice e a Lei dos Cossenos.mpg</basename>
    </file>
    <file>
        <id_resource>1048</id_resource>
        <id_file>16612</id_file>
        <type>6</type>
        <dirname>1048</dirname>
        <basename>030. Alice e o Cosseno da Diferenca de Arcos.mpg</basename>
    </file>
    <file>
        <id_resource>1049</id_resource>
        <id_file>16615</id_file>
        <type>6</type>
        <dirname>1049</dirname>
        <basename>123. Amuleto Magico.mpg</basename>
    </file>
    <file>
        <id_resource>1050</id_resource>
        <id_file>16618</id_file>
        <type>6</type>
        <dirname>1050</dirname>
        <basename>047. A Aparicao.mpg</basename>
    </file>
    <file>
        <id_resource>1051</id_resource>
        <id_file>16621</id_file>
        <type>6</type>
        <dirname>1051</dirname>
        <basename>151. Arte e Matematica.mpg</basename>
    </file>
    <file>
        <id_resource>1052</id_resource>
        <id_file>16624</id_file>
        <type>6</type>
        <dirname>1052</dirname>
        <basename>096.-Atleticano-x-Rio-Grandense.mpg</basename>
    </file>
    <file>
        <id_resource>1053</id_resource>
        <id_file>16627</id_file>
        <type>6</type>
        <dirname>1053</dirname>
        <basename>122. Uma Aventura de RPG.mpg</basename>
    </file>
    <file>
        <id_resource>1054</id_resource>
        <id_file>16630</id_file>
        <type>6</type>
        <dirname>1054</dirname>
        <basename>010. As Aventuras de Radix.mpg</basename>
    </file>
    <file>
        <id_resource>1055</id_resource>
        <id_file>16633</id_file>
        <type>6</type>
        <dirname>1055</dirname>
        <basename>072. Bombons a Granel.mpg</basename>
    </file>
    <file>
        <id_resource>1056</id_resource>
        <id_file>16636</id_file>
        <type>6</type>
        <dirname>1056</dirname>
        <basename>021. Brasil x Argentina.mpg</basename>
    </file>
    <file>
        <id_resource>1057</id_resource>
        <id_file>16639</id_file>
        <type>6</type>
        <dirname>1057</dirname>
        <basename>101. Breve Relato do Fim.mpg</basename>
    </file>
    <file>
        <id_resource>1058</id_resource>
        <id_file>16642</id_file>
        <type>6</type>
        <dirname>1058</dirname>
        <basename>026. Os Cacadores de Sons de Fibonacci.mpg</basename>
    </file>
    <file>
        <id_resource>1059</id_resource>
        <id_file>16645</id_file>
        <type>6</type>
        <dirname>1059</dirname>
        <basename>098. Cada Grafico no Seu Galho.mpg</basename>
    </file>
    <file>
        <id_resource>1060</id_resource>
        <id_file>16648</id_file>
        <type>6</type>
        <dirname>1060</dirname>
        <basename>142. Um Caminho para Combater a Dengue.mpg</basename>
    </file>
    <file>
        <id_resource>1061</id_resource>
        <id_file>16651</id_file>
        <type>6</type>
        <dirname>1061</dirname>
        <basename>013. Um Caminho para o Curral.mpg</basename>
    </file>
    <file>
        <id_resource>1062</id_resource>
        <id_file>16654</id_file>
        <type>6</type>
        <dirname>1062</dirname>
        <basename>121. Cara ou Coroa.mpg</basename>
    </file>
    <file>
        <id_resource>1064</id_resource>
        <id_file>16660</id_file>
        <type>6</type>
        <dirname>1064</dirname>
        <basename>113. A Carta.mpg</basename>
    </file>
    <file>
        <id_resource>1065</id_resource>
        <id_file>16663</id_file>
        <type>6</type>
        <dirname>1065</dirname>
        <basename>086. A Cartomante.mpg</basename>
    </file>
    <file>
        <id_resource>1066</id_resource>
        <id_file>16666</id_file>
        <type>6</type>
        <dirname>1066</dirname>
        <basename>057. Um Certo Fator de Escala.mpg</basename>
    </file>
    <file>
        <id_resource>1067</id_resource>
        <id_file>16669</id_file>
        <type>6</type>
        <dirname>1067</dirname>
        <basename>112. A Cesar o que E de Cesar.mpg</basename>
    </file>
    <file>
        <id_resource>1068</id_resource>
        <id_file>16672</id_file>
        <type>6</type>
        <dirname>1068</dirname>
        <basename>141.-Cloro-na-Piscina.mpg</basename>
    </file>
    <file>
        <id_resource>1069</id_resource>
        <id_file>16675</id_file>
        <type>6</type>
        <dirname>1069</dirname>
        <basename>008. O Codigo Pascal.mpg</basename>
    </file>
    <file>
        <id_resource>1070</id_resource>
        <id_file>16678</id_file>
        <type>6</type>
        <dirname>1070</dirname>
        <basename>020. Coisa de Passarinho.mpg</basename>
    </file>
    <file>
        <id_resource>1071</id_resource>
        <id_file>16681</id_file>
        <type>6</type>
        <dirname>1071</dirname>
        <basename>019. Coisas do Amor.mpg</basename>
    </file>
    <file>
        <id_resource>1072</id_resource>
        <id_file>16684</id_file>
        <type>6</type>
        <dirname>1072</dirname>
        <basename>128.-Colmeia-Global.mpg</basename>
    </file>
    <file>
        <id_resource>1073</id_resource>
        <id_file>16687</id_file>
        <type>6</type>
        <dirname>1073</dirname>
        <basename>003. Comendo Numeros.mpg</basename>
    </file>
    <file>
        <id_resource>1074</id_resource>
        <id_file>16690</id_file>
        <type>6</type>
        <dirname>1074</dirname>
        <basename>108.-A-Comunidade.mpg</basename>
    </file>
    <file>
        <id_resource>1076</id_resource>
        <id_file>16694</id_file>
        <type>6</type>
        <dirname>1076</dirname>
        <basename>001. A Cooperativa de Leite.mpg</basename>
    </file>
    <file>
        <id_resource>1077</id_resource>
        <id_file>16697</id_file>
        <type>6</type>
        <dirname>1077</dirname>
        <basename>040. Cozinha Cientifica.mpg</basename>
    </file>
    <file>
        <id_resource>1078</id_resource>
        <id_file>16700</id_file>
        <type>6</type>
        <dirname>1078</dirname>
        <basename>115. Criador e Criatura.mpg</basename>
    </file>
    <file>
        <id_resource>1079</id_resource>
        <id_file>16703</id_file>
        <type>6</type>
        <dirname>1079</dirname>
        <basename>018. O Crime da Rua do Gasometro.mpg</basename>
    </file>
    <file>
        <id_resource>1080</id_resource>
        <id_file>16706</id_file>
        <type>6</type>
        <dirname>1080</dirname>
        <basename>155. A Danca do Sol.mpg</basename>
    </file>
    <file>
        <id_resource>1081</id_resource>
        <id_file>16709</id_file>
        <type>6</type>
        <dirname>1081</dirname>
        <basename>154.-A-Danca-do-Embaralhamento.mpg</basename>
    </file>
    <file>
        <id_resource>1082</id_resource>
        <id_file>16712</id_file>
        <type>6</type>
        <dirname>1082</dirname>
        <basename>041. Dando Ibope.mpg</basename>
    </file>
    <file>
        <id_resource>1083</id_resource>
        <id_file>16715</id_file>
        <type>6</type>
        <dirname>1083</dirname>
        <basename>009. De Malas Prontas.mpg</basename>
    </file>
    <file>
        <id_resource>1084</id_resource>
        <id_file>16718</id_file>
        <type>6</type>
        <dirname>1084</dirname>
        <basename>037. O Desafio das Correlacoes Espurias.mpg</basename>
    </file>
    <file>
        <id_resource>1085</id_resource>
        <id_file>16721</id_file>
        <type>6</type>
        <dirname>1085</dirname>
        <basename>087. Desejos.mpg</basename>
    </file>
    <file>
        <id_resource>1086</id_resource>
        <id_file>16724</id_file>
        <type>6</type>
        <dirname>1086</dirname>
        <basename>035. Desenhando Ondas.mpg</basename>
    </file>
    <file>
        <id_resource>1087</id_resource>
        <id_file>16727</id_file>
        <type>6</type>
        <dirname>1087</dirname>
        <basename>139. Desvendando o Calendario.mpg</basename>
    </file>
    <file>
        <id_resource>1088</id_resource>
        <id_file>16730</id_file>
        <type>6</type>
        <dirname>1088</dirname>
        <basename>094. As Desventuras da Mae Joana.mpg</basename>
    </file>
    <file>
        <id_resource>1089</id_resource>
        <id_file>16733</id_file>
        <type>6</type>
        <dirname>1089</dirname>
        <basename>045.-O-Dilema-do-Prisioneiro.mpg</basename>
    </file>
    <file>
        <id_resource>1090</id_resource>
        <id_file>16736</id_file>
        <type>6</type>
        <dirname>1090</dirname>
        <basename>089. Dinamica de Grupos.mpg</basename>
    </file>
    <file>
        <id_resource>1091</id_resource>
        <id_file>16739</id_file>
        <type>6</type>
        <dirname>1091</dirname>
        <basename>092. Direitos do Consumidor.mpg</basename>
    </file>
    <file>
        <id_resource>1092</id_resource>
        <id_file>16742</id_file>
        <type>6</type>
        <dirname>1092</dirname>
        <basename>129. E Agora, Jose.mpg</basename>
    </file>
    <file>
        <id_resource>1093</id_resource>
        <id_file>16745</id_file>
        <type>6</type>
        <dirname>1093</dirname>
        <basename>106.-Em-Equilibrio.mpg</basename>
    </file>
    <file>
        <id_resource>1094</id_resource>
        <id_file>16748</id_file>
        <type>6</type>
        <dirname>1094</dirname>
        <basename>090. Embalagens.mpg</basename>
    </file>
    <file>
        <id_resource>1095</id_resource>
        <id_file>16751</id_file>
        <type>6</type>
        <dirname>1095</dirname>
        <basename>083. Um Encontro Inusitado.mpg</basename>
    </file>
    <file>
        <id_resource>1096</id_resource>
        <id_file>16754</id_file>
        <type>6</type>
        <dirname>1096</dirname>
        <basename>058. Entrando pelo Tunel.mpg</basename>
    </file>
    <file>
        <id_resource>1097</id_resource>
        <id_file>16757</id_file>
        <type>6</type>
        <dirname>1097</dirname>
        <basename>082. Esse tal de Bhaskara.mpg</basename>
    </file>
    <file>
        <id_resource>1098</id_resource>
        <id_file>16760</id_file>
        <type>6</type>
        <dirname>1098</dirname>
        <basename>097. A Experiencia.mpg</basename>
    </file>
    <file>
        <id_resource>1099</id_resource>
        <id_file>16763</id_file>
        <type>6</type>
        <dirname>1099</dirname>
        <basename>120. Formula Magica.mpg</basename>
    </file>
    <file>
        <id_resource>1100</id_resource>
        <id_file>16766</id_file>
        <type>6</type>
        <dirname>1100</dirname>
        <basename>071. O Gabarito Secreto.mpg</basename>
    </file>
    <file>
        <id_resource>1101</id_resource>
        <id_file>16769</id_file>
        <type>6</type>
        <dirname>1101</dirname>
        <basename>074. Gasolina ou Alcool.mpg</basename>
    </file>
    <file>
        <id_resource>1102</id_resource>
        <id_file>16772</id_file>
        <type>6</type>
        <dirname>1102</dirname>
        <basename>116.-As-Aventuras-do-Geodetetive-1.mpg</basename>
    </file>
    <file>
        <id_resource>1103</id_resource>
        <id_file>16775</id_file>
        <type>6</type>
        <dirname>1103</dirname>
        <basename>117.-As-Aventuras-do-Geodetetive-2.mpg</basename>
    </file>
    <file>
        <id_resource>1104</id_resource>
        <id_file>16778</id_file>
        <type>6</type>
        <dirname>1104</dirname>
        <basename>145.-As-Aventuras-do-Geodetetive-3.mpg</basename>
    </file>
    <file>
        <id_resource>1106</id_resource>
        <id_file>16782</id_file>
        <type>6</type>
        <dirname>1106</dirname>
        <basename>147.-As-Aventuras-do-Geodetetive-5.mpg</basename>
    </file>
    <file>
        <id_resource>1107</id_resource>
        <id_file>16785</id_file>
        <type>6</type>
        <dirname>1107</dirname>
        <basename>148.-As-Aventuras-do-Geodetetive-6.mpg</basename>
    </file>
    <file>
        <id_resource>1108</id_resource>
        <id_file>16788</id_file>
        <type>6</type>
        <dirname>1108</dirname>
        <basename>144.-O-Golpe.mpg</basename>
    </file>
    <file>
        <id_resource>1109</id_resource>
        <id_file>16791</id_file>
        <type>6</type>
        <dirname>1109</dirname>
        <basename>079. Grande Hotel.mpg</basename>
    </file>
    <file>
        <id_resource>1110</id_resource>
        <id_file>16794</id_file>
        <type>6</type>
        <dirname>1110</dirname>
        <basename>080. Grande Hotel 2.mpg</basename>
    </file>
    <file>
        <id_resource>1111</id_resource>
        <id_file>16797</id_file>
        <type>6</type>
        <dirname>1111</dirname>
        <basename>028. O Grilo Cantante.mpg</basename>
    </file>
    <file>
        <id_resource>1112</id_resource>
        <id_file>16800</id_file>
        <type>6</type>
        <dirname>1112</dirname>
        <basename>004. O Guardador de Aguas.mpg</basename>
    </file>
    <file>
        <id_resource>1113</id_resource>
        <id_file>16803</id_file>
        <type>6</type>
        <dirname>1113</dirname>
        <basename>124. Halloween.mpg</basename>
    </file>
    <file>
        <id_resource>1114</id_resource>
        <id_file>16806</id_file>
        <type>6</type>
        <dirname>1114</dirname>
        <basename>125. Heranca de Familia.mpg</basename>
    </file>
    <file>
        <id_resource>1115</id_resource>
        <id_file>16809</id_file>
        <type>6</type>
        <dirname>1115</dirname>
        <basename>152. A Historia de Mussaraf.mpg</basename>
    </file>
    <file>
        <id_resource>1116</id_resource>
        <id_file>16812</id_file>
        <type>6</type>
        <dirname>1116</dirname>
        <basename>107.-O-Hit-dos-Bits.mpg</basename>
    </file>
    <file>
        <id_resource>1117</id_resource>
        <id_file>16815</id_file>
        <type>6</type>
        <dirname>1117</dirname>
        <basename>081. Hotel de Hilbert.mpg</basename>
    </file>
    <file>
        <id_resource>1118</id_resource>
        <id_file>16818</id_file>
        <type>6</type>
        <dirname>1118</dirname>
        <basename>042. Huguinho e Zezinho.mpg</basename>
    </file>
    <file>
        <id_resource>1119</id_resource>
        <id_file>16821</id_file>
        <type>6</type>
        <dirname>1119</dirname>
        <basename>043. A Incrivel Piritipoca da Serra.mpg</basename>
    </file>
    <file>
        <id_resource>1120</id_resource>
        <id_file>16824</id_file>
        <type>6</type>
        <dirname>1120</dirname>
        <basename>130. Os Infinitos de Cantor.mpg</basename>
    </file>
    <file>
        <id_resource>1121</id_resource>
        <id_file>16827</id_file>
        <type>6</type>
        <dirname>1121</dirname>
        <basename>061. Jardim de Numeros.mpg</basename>
    </file>
    <file>
        <id_resource>1122</id_resource>
        <id_file>16830</id_file>
        <type>6</type>
        <dirname>1122</dirname>
        <basename>023. O Jogo de Dados de Mozart.mpg</basename>
    </file>
    <file>
        <id_resource>1123</id_resource>
        <id_file>16833</id_file>
        <type>6</type>
        <dirname>1123</dirname>
        <basename>046. Jogos.mpg</basename>
    </file>
    <file>
        <id_resource>1124</id_resource>
        <id_file>16836</id_file>
        <type>6</type>
        <dirname>1124</dirname>
        <basename>085. Juros Divididos Divida Crescente.mpg</basename>
    </file>
    <file>
        <id_resource>1125</id_resource>
        <id_file>16839</id_file>
        <type>6</type>
        <dirname>1125</dirname>
        <basename>039. Lembrancas de Sofia.mpg</basename>
    </file>
    <file>
        <id_resource>1126</id_resource>
        <id_file>16842</id_file>
        <type>6</type>
        <dirname>1126</dirname>
        <basename>055. A Lenda de Dido.mpg</basename>
    </file>
    <file>
        <id_resource>1127</id_resource>
        <id_file>16845</id_file>
        <type>6</type>
        <dirname>1127</dirname>
        <basename>075. Logica de Alice.mpg</basename>
    </file>
    <file>
        <id_resource>1128</id_resource>
        <id_file>16848</id_file>
        <type>6</type>
        <dirname>1128</dirname>
        <basename>011. A Loira do Banheiro.mpg</basename>
    </file>
    <file>
        <id_resource>1129</id_resource>
        <id_file>16851</id_file>
        <type>6</type>
        <dirname>1129</dirname>
        <basename>024. Lutie de Proporcoes.mpg</basename>
    </file>
    <file>
        <id_resource>1130</id_resource>
        <id_file>16854</id_file>
        <type>6</type>
        <dirname>1130</dirname>
        <basename>066. A Mae.mpg</basename>
    </file>
    <file>
        <id_resource>1131</id_resource>
        <id_file>16857</id_file>
        <type>6</type>
        <dirname>1131</dirname>
        <basename>027. O Magico das Arabias.mpg</basename>
    </file>
    <file>
        <id_resource>1132</id_resource>
        <id_file>16860</id_file>
        <type>6</type>
        <dirname>1132</dirname>
        <basename>109. A Maldicao da Piramide.mpg</basename>
    </file>
    <file>
        <id_resource>1133</id_resource>
        <id_file>16863</id_file>
        <type>6</type>
        <dirname>1133</dirname>
        <basename>002. A Mancha.mpg</basename>
    </file>
    <file>
        <id_resource>1134</id_resource>
        <id_file>16866</id_file>
        <type>6</type>
        <dirname>1134</dirname>
        <basename>138. Medindo a Terra.mpg</basename>
    </file>
    <file>
        <id_resource>1135</id_resource>
        <id_file>16869</id_file>
        <type>6</type>
        <dirname>1135</dirname>
        <basename>033. Musica Quase por Acaso.mpg</basename>
    </file>
    <file>
        <id_resource>1136</id_resource>
        <id_file>16872</id_file>
        <type>6</type>
        <dirname>1136</dirname>
        <basename>034. Musica Quase sem Compositor.mpg</basename>
    </file>
    <file>
        <id_resource>1137</id_resource>
        <id_file>16875</id_file>
        <type>6</type>
        <dirname>1137</dirname>
        <basename>062. Na Cauda do Cometa.mpg</basename>
    </file>
    <file>
        <id_resource>1138</id_resource>
        <id_file>16878</id_file>
        <type>6</type>
        <dirname>1138</dirname>
        <basename>054. Naturalmente.mpg</basename>
    </file>
    <file>
        <id_resource>1139</id_resource>
        <id_file>16881</id_file>
        <type>6</type>
        <dirname>1139</dirname>
        <basename>017. Noite de Forro.mpg</basename>
    </file>
    <file>
        <id_resource>1140</id_resource>
        <id_file>16884</id_file>
        <type>6</type>
        <dirname>1140</dirname>
        <basename>050. O Sonho.mpg</basename>
    </file>
    <file>
        <id_resource>1141</id_resource>
        <id_file>16887</id_file>
        <type>6</type>
        <dirname>1141</dirname>
        <basename>105. O Sonho Continua.mpg</basename>
    </file>
    <file>
        <id_resource>1142</id_resource>
        <id_file>16890</id_file>
        <type>6</type>
        <dirname>1142</dirname>
        <basename>104. O Sonho nao Acabou.mpg</basename>
    </file>
    <file>
        <id_resource>1143</id_resource>
        <id_file>16893</id_file>
        <type>6</type>
        <dirname>1143</dirname>
        <basename>025. Oferenda Musical de Bach.mpg</basename>
    </file>
    <file>
        <id_resource>1144</id_resource>
        <id_file>16896</id_file>
        <type>6</type>
        <dirname>1144</dirname>
        <basename>099. Olha o Sanduiche.mpg</basename>
    </file>
    <file>
        <id_resource>1145</id_resource>
        <id_file>16899</id_file>
        <type>6</type>
        <dirname>1145</dirname>
        <basename>012. Os Angulos e as Torres.mpg</basename>
    </file>
    <file>
        <id_resource>1146</id_resource>
        <id_file>16902</id_file>
        <type>6</type>
        <dirname>1146</dirname>
        <basename>102. Osso Duro de Roer.mpg</basename>
    </file>
    <file>
        <id_resource>1147</id_resource>
        <id_file>16905</id_file>
        <type>6</type>
        <dirname>1147</dirname>
        <basename>126. Overdose.mpg</basename>
    </file>
    <file>
        <id_resource>1148</id_resource>
        <id_file>16908</id_file>
        <type>6</type>
        <dirname>1148</dirname>
        <basename>095. Pandemia.mpg</basename>
    </file>
    <file>
        <id_resource>1149</id_resource>
        <id_file>16911</id_file>
        <type>6</type>
        <dirname>1149</dirname>
        <basename>091. Panquecas da Dona Gloria.mpg</basename>
    </file>
    <file>
        <id_resource>1150</id_resource>
        <id_file>16914</id_file>
        <type>6</type>
        <dirname>1150</dirname>
        <basename>063. Para Correr a Sao Silvestre.mpg</basename>
    </file>
    <file>
        <id_resource>1151</id_resource>
        <id_file>16917</id_file>
        <type>6</type>
        <dirname>1151</dirname>
        <basename>064. Para Salvar o Mundo.mpg</basename>
    </file>
    <file>
        <id_resource>1154</id_resource>
        <id_file>16926</id_file>
        <type>6</type>
        <dirname>1154</dirname>
        <basename>136.-Pelas-Lentes-da-Matematica.mpg</basename>
    </file>
    <file>
        <id_resource>1155</id_resource>
        <id_file>16929</id_file>
        <type>6</type>
        <dirname>1155</dirname>
        <basename>069. Perdido no Globo.mpg</basename>
    </file>
    <file>
        <id_resource>1156</id_resource>
        <id_file>16932</id_file>
        <type>6</type>
        <dirname>1156</dirname>
        <basename>056.-Um-Poema-e-Tres-Quebra-Cabecas.mpg</basename>
    </file>
    <file>
        <id_resource>1157</id_resource>
        <id_file>16935</id_file>
        <type>6</type>
        <dirname>1157</dirname>
        <basename>060. Um Ponto de Vista.mpg</basename>
    </file>
    <file>
        <id_resource>1158</id_resource>
        <id_file>16938</id_file>
        <type>6</type>
        <dirname>1158</dirname>
        <basename>111. Pra La de Bagda.mpg</basename>
    </file>
    <file>
        <id_resource>1159</id_resource>
        <id_file>16941</id_file>
        <type>6</type>
        <dirname>1159</dirname>
        <basename>132. O Principe de Sofia.mpg</basename>
    </file>
    <file>
        <id_resource>1160</id_resource>
        <id_file>16944</id_file>
        <type>6</type>
        <dirname>1160</dirname>
        <basename>084.-O-Problema-da-Cerca.mpg</basename>
    </file>
    <file>
        <id_resource>1161</id_resource>
        <id_file>16947</id_file>
        <type>6</type>
        <dirname>1161</dirname>
        <basename>032. Procurando Xenakis.mpg</basename>
    </file>
    <file>
        <id_resource>1162</id_resource>
        <id_file>16950</id_file>
        <type>6</type>
        <dirname>1162</dirname>
        <basename>016. Prova de Alternativas.mpg</basename>
    </file>
    <file>
        <id_resource>1164</id_resource>
        <id_file>16956</id_file>
        <type>6</type>
        <dirname>1164</dirname>
        <basename>131. Qual o Melhor Caminho.mpg</basename>
    </file>
    <file>
        <id_resource>1165</id_resource>
        <id_file>16959</id_file>
        <type>6</type>
        <dirname>1165</dirname>
        <basename>110. Que Saco.mpg</basename>
    </file>
    <file>
        <id_resource>1166</id_resource>
        <id_file>16962</id_file>
        <type>6</type>
        <dirname>1166</dirname>
        <basename>005. Que a Forca Esteja com Voce.mpg</basename>
    </file>
    <file>
        <id_resource>1167</id_resource>
        <id_file>16965</id_file>
        <type>6</type>
        <dirname>1167</dirname>
        <basename>133. Quem Quer Ser um Milionario.mpg</basename>
    </file>
    <file>
        <id_resource>1168</id_resource>
        <id_file>16968</id_file>
        <type>6</type>
        <dirname>1168</dirname>
        <basename>150. A Razao dos Irracionais.mpg</basename>
    </file>
    <file>
        <id_resource>1169</id_resource>
        <id_file>16971</id_file>
        <type>6</type>
        <dirname>1169</dirname>
        <basename>076. A Revanche de Alice.mpg</basename>
    </file>
    <file>
        <id_resource>1170</id_resource>
        <id_file>16974</id_file>
        <type>6</type>
        <dirname>1170</dirname>
        <basename>153. Revendo a Moratoria.mpg</basename>
    </file>
    <file>
        <id_resource>1171</id_resource>
        <id_file>16977</id_file>
        <type>6</type>
        <dirname>1171</dirname>
        <basename>088. Roda Roda.mpg</basename>
    </file>
    <file>
        <id_resource>1172</id_resource>
        <id_file>16980</id_file>
        <type>6</type>
        <dirname>1172</dirname>
        <basename>051. Roda de Samba.mpg</basename>
    </file>
    <file>
        <id_resource>1173</id_resource>
        <id_file>16983</id_file>
        <type>6</type>
        <dirname>1173</dirname>
        <basename>053. Roda do Sonho.mpg</basename>
    </file>
    <file>
        <id_resource>1174</id_resource>
        <id_file>16986</id_file>
        <type>6</type>
        <dirname>1174</dirname>
        <basename>100. Salvador, o Hipocondriaco.mpg</basename>
    </file>
    <file>
        <id_resource>1175</id_resource>
        <id_file>16989</id_file>
        <type>6</type>
        <dirname>1175</dirname>
        <basename>006. Se Eu Fosse Voce.mpg</basename>
    </file>
    <file>
        <id_resource>1176</id_resource>
        <id_file>16992</id_file>
        <type>6</type>
        <dirname>1176</dirname>
        <basename>134. Sem Discriminacao.mpg</basename>
    </file>
    <file>
        <id_resource>1177</id_resource>
        <id_file>16995</id_file>
        <type>6</type>
        <dirname>1177</dirname>
        <basename>036. Sinfonia de Poliedros.mpg</basename>
    </file>
    <file>
        <id_resource>1178</id_resource>
        <id_file>16998</id_file>
        <type>6</type>
        <dirname>1178</dirname>
        <basename>044. O Sonho Dourado.mpg</basename>
    </file>
    <file>
        <id_resource>1179</id_resource>
        <id_file>17001</id_file>
        <type>6</type>
        <dirname>1179</dirname>
        <basename>140. Surpresa para os Calouros.mpg</basename>
    </file>
    <file>
        <id_resource>1180</id_resource>
        <id_file>17004</id_file>
        <type>6</type>
        <dirname>1180</dirname>
        <basename>048. Os Suspeitos.mpg</basename>
    </file>
    <file>
        <id_resource>1181</id_resource>
        <id_file>17007</id_file>
        <type>6</type>
        <dirname>1181</dirname>
        <basename>114.-Tempos-de-Guerra.mpg</basename>
    </file>
    <file>
        <id_resource>1182</id_resource>
        <id_file>17010</id_file>
        <type>6</type>
        <dirname>1182</dirname>
        <basename>049. Terremoto Brasileiro.mpg</basename>
    </file>
    <file>
        <id_resource>1183</id_resource>
        <id_file>17013</id_file>
        <type>6</type>
        <dirname>1183</dirname>
        <basename>137. Tesouro Cartesiano.mpg</basename>
    </file>
    <file>
        <id_resource>1184</id_resource>
        <id_file>17016</id_file>
        <type>6</type>
        <dirname>1184</dirname>
        <basename>015. Teste de Gravidez.mpg</basename>
    </file>
    <file>
        <id_resource>1185</id_resource>
        <id_file>17019</id_file>
        <type>6</type>
        <dirname>1185</dirname>
        <basename>059. Triangular E Preciso.mpg</basename>
    </file>
    <file>
        <id_resource>1186</id_resource>
        <id_file>17022</id_file>
        <type>6</type>
        <dirname>1186</dirname>
        <basename>029.-Tudo-Que-Voce-Sempre-Quis-Perguntar.mpg</basename>
    </file>
    <file>
        <id_resource>1187</id_resource>
        <id_file>17025</id_file>
        <type>6</type>
        <dirname>1187</dirname>
        <basename>103.-Um-Sonho-Complexo.mpg</basename>
    </file>
    <file>
        <id_resource>1188</id_resource>
        <id_file>17028</id_file>
        <type>6</type>
        <dirname>1188</dirname>
        <basename>135. Voce Disse Cristalografia.mpg</basename>
    </file>
    <file>
        <id_resource>1189</id_resource>
        <id_file>17031</id_file>
        <type>6</type>
        <dirname>1189</dirname>
        <basename>118. A Velha Historia das Multidoes.mpg</basename>
    </file>
    <file>
        <id_resource>1190</id_resource>
        <id_file>17034</id_file>
        <type>6</type>
        <dirname>1190</dirname>
        <basename>143. Venda Segura.mpg</basename>
    </file>
    <file>
        <id_resource>1191</id_resource>
        <id_file>17037</id_file>
        <type>6</type>
        <dirname>1191</dirname>
        <basename>070. Vou de Taxi.mpg</basename>
    </file>
    <file>
        <id_resource>1192</id_resource>
        <id_file>17040</id_file>
        <type>6</type>
        <dirname>1192</dirname>
        <basename>073. A Voz do Interior.mpg</basename>
    </file>
    <file>
        <id_resource>1193</id_resource>
        <id_file>17043</id_file>
        <type>6</type>
        <dirname>1193</dirname>
        <basename>007. Xeque-Mate.mpg</basename>
    </file>
    <file>
        <id_resource>1194</id_resource>
        <id_file>17046</id_file>
        <type>6</type>
        <dirname>1194</dirname>
        <basename>Administrador e Contador.mpg</basename>
    </file>
    <file>
        <id_resource>1195</id_resource>
        <id_file>17049</id_file>
        <type>6</type>
        <dirname>1195</dirname>
        <basename>Advogado e Engenheiro Eletrico.mpg</basename>
    </file>
    <file>
        <id_resource>1196</id_resource>
        <id_file>17052</id_file>
        <type>6</type>
        <dirname>1196</dirname>
        <basename>Agrimensor e Geologo.mov</basename>
    </file>
    <file>
        <id_resource>1197</id_resource>
        <id_file>17055</id_file>
        <type>6</type>
        <dirname>1197</dirname>
        <basename>Agronomo e Fisioterapeuta.mpg</basename>
    </file>
    <file>
        <id_resource>1198</id_resource>
        <id_file>17058</id_file>
        <type>6</type>
        <dirname>1198</dirname>
        <basename>Analista de Sistemas e Engenheiro da Computacao.mpg</basename>
    </file>
    <file>
        <id_resource>1199</id_resource>
        <id_file>17061</id_file>
        <type>6</type>
        <dirname>1199</dirname>
        <basename>Arquiteto e Engenheiro Civil.mov</basename>
    </file>
    <file>
        <id_resource>1200</id_resource>
        <id_file>17064</id_file>
        <type>6</type>
        <dirname>1200</dirname>
        <basename>Artista Plastico e Designer Grafico.mpg</basename>
    </file>
    <file>
        <id_resource>1201</id_resource>
        <id_file>17067</id_file>
        <type>6</type>
        <dirname>1201</dirname>
        <basename>Astronomo e Fisico.mpg</basename>
    </file>
    <file>
        <id_resource>1202</id_resource>
        <id_file>17070</id_file>
        <type>6</type>
        <dirname>1202</dirname>
        <basename>Atuario e Estatistico.mpg</basename>
    </file>
    <file>
        <id_resource>1203</id_resource>
        <id_file>17073</id_file>
        <type>6</type>
        <dirname>1203</dirname>
        <basename>Biologo e Nutricionista.mov</basename>
    </file>
    <file>
        <id_resource>1204</id_resource>
        <id_file>17076</id_file>
        <type>6</type>
        <dirname>1204</dirname>
        <basename>Chef de Cozinha e Estilista.mpg</basename>
    </file>
    <file>
        <id_resource>1205</id_resource>
        <id_file>17079</id_file>
        <type>6</type>
        <dirname>1205</dirname>
        <basename>Comerciante e Corretor de Imoveis.mpg</basename>
    </file>
    <file>
        <id_resource>1206</id_resource>
        <id_file>17082</id_file>
        <type>6</type>
        <dirname>1206</dirname>
        <basename>Controlador de Trafego Aereo e Piloto de Helicoptero.mpg</basename>
    </file>
    <file>
        <id_resource>1207</id_resource>
        <id_file>17085</id_file>
        <type>6</type>
        <dirname>1207</dirname>
        <basename>Economista e Comercio Exterior.mpg</basename>
    </file>
    <file>
        <id_resource>1208</id_resource>
        <id_file>17088</id_file>
        <type>6</type>
        <dirname>1208</dirname>
        <basename>Engenheira de Alimentos e Quimico.mpg</basename>
    </file>
    <file>
        <id_resource>1209</id_resource>
        <id_file>17091</id_file>
        <type>6</type>
        <dirname>1209</dirname>
        <basename>Esportista e Fotografo.mpg</basename>
    </file>
    <file>
        <id_resource>1210</id_resource>
        <id_file>17094</id_file>
        <type>6</type>
        <dirname>1210</dirname>
        <basename>Farmaceutica e Produtora de Eventos.mpg</basename>
    </file>
    <file>
        <id_resource>1211</id_resource>
        <id_file>17097</id_file>
        <type>6</type>
        <dirname>1211</dirname>
        <basename>Hotelaria e Logistica.mov</basename>
    </file>
    <file>
        <id_resource>1212</id_resource>
        <id_file>17100</id_file>
        <type>6</type>
        <dirname>1212</dirname>
        <basename>Matematico e Musico.mpg</basename>
    </file>
    <file>
        <id_resource>1213</id_resource>
        <id_file>17103</id_file>
        <type>6</type>
        <dirname>1213</dirname>
        <basename>Meteorologista e Turismo.mpg</basename>
    </file>
    <file>
        <id_resource>1214</id_resource>
        <id_file>17106</id_file>
        <type>6</type>
        <dirname>1214</dirname>
        <basename>Odontologia e Medico.mov</basename>
    </file>
    <file>
        <id_resource>1215</id_resource>
        <id_file>17109</id_file>
        <type>6</type>
        <dirname>1215</dirname>
        <basename>Produtor de Cinema e Publicitario.mpg</basename>
    </file>
    <file>
        <id_resource>1216</id_resource>
        <id_file>17112</id_file>
        <type>6</type>
        <dirname>1216</dirname>
        <basename>Professor Universitario e Bancaria.mpg</basename>
    </file>
    <file>
        <id_resource>1217</id_resource>
        <id_file>17115</id_file>
        <type>6</type>
        <dirname>1217</dirname>
        <basename>Secretaria e Engenheiro de Telecomunicacoes.mpg</basename>
    </file>
    <file>
        <id_resource>1218</id_resource>
        <id_file>17118</id_file>
        <type>6</type>
        <dirname>1218</dirname>
        <basename>Zootecnia e Veterinaria.mov</basename>
    </file>
    <file>
        <id_resource>1364</id_resource>
        <id_file>17599</id_file>
        <type>6</type>
        <dirname>1364</dirname>
        <basename>AVALANCHESVCD_NTSC_Lg.mpg</basename>
    </file>
    <file>
        <id_resource>1382</id_resource>
        <id_file>17733</id_file>
        <type>6</type>
        <dirname>1382</dirname>
        <basename>CAIXA DE PAPELSVCD_NTSC_Lg.mpg</basename>
    </file>
    <file>
        <id_resource>1384</id_resource>
        <id_file>17739</id_file>
        <type>6</type>
        <dirname>1384</dirname>
        <basename>MONTANHAS GEOMETRICASSVCD_NTSC_Lg.mpg</basename>
    </file>
    <file>
        <id_resource>1385</id_resource>
        <id_file>17742</id_file>
        <type>6</type>
        <dirname>1385</dirname>
        <basename>PADROES NO PLANOSVCD_NTSC_Lg.mpg</basename>
    </file>
    <file>
        <id_resource>1163</id_resource>
        <id_file>17849</id_file>
        <type>6</type>
        <dirname>1163</dirname>
        <basename>127. Quadra Poliesportiva.mpg</basename>
    </file>
    <file>
        <id_resource>1105</id_resource>
        <id_file>17901</id_file>
        <type>6</type>
        <dirname>1105</dirname>
        <basename>146. As Aventuras do Geodetetive 4.mpg</basename>
    </file>
    <file>
        <id_resource>1075</id_resource>
        <id_file>17904</id_file>
        <type>6</type>
        <dirname>1075</dirname>
        <basename>067. Conclusoes Precipitadas.mpg</basename>
    </file>
    <file>
        <id_resource>1153</id_resource>
        <id_file>17978</id_file>
        <type>6</type>
        <dirname>1153</dirname>
        <basename>068. A Parte do Leao.mpg</basename>
    </file>
    <file>
        <id_resource>1063</id_resource>
        <id_file>18102</id_file>
        <type>6</type>
        <dirname>1063</dirname>
        <basename>093. Carro Flex.mpg</basename>
    </file>
    <file>
        <id_resource>1387</id_resource>
        <id_file>18109</id_file>
        <type>6</type>
        <dirname>1387</dirname>
        <basename>022. Descobrindo o Algoritimo de Guido.mpg</basename>
    </file>
    <file>
        <id_resource>1152</id_resource>
        <id_file>18136</id_file>
        <type>6</type>
        <dirname>1152</dirname>
        <basename>077. Alice, o Paradoxo e a Formalizacao.mpg</basename>
    </file>
    <file>
        <id_resource>1040</id_resource>
        <id_file>16589</id_file>
        <type>7</type>
        <dirname>1040</dirname>
        <basename>321misterio.pdf</basename>
    </file>
    <file>
        <id_resource>1041</id_resource>
        <id_file>16592</id_file>
        <type>7</type>
        <dirname>1041</dirname>
        <basename>aesperadameianoite.pdf</basename>
    </file>
    <file>
        <id_resource>1042</id_resource>
        <id_file>16595</id_file>
        <type>7</type>
        <dirname>1042</dirname>
        <basename>abelhasmatematicas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1043</id_resource>
        <id_file>16598</id_file>
        <type>7</type>
        <dirname>1043</dirname>
        <basename>acaoreacaocorrelacao.pdf</basename>
    </file>
    <file>
        <id_resource>1044</id_resource>
        <id_file>16601</id_file>
        <type>7</type>
        <dirname>1044</dirname>
        <basename>achoqueviumcoelhinho-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1045</id_resource>
        <id_file>16604</id_file>
        <type>7</type>
        <dirname>1045</dirname>
        <basename>afogandoemzeros-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1046</id_resource>
        <id_file>16607</id_file>
        <type>7</type>
        <dirname>1046</dirname>
        <basename>aliceealgumasrelacoestrigonometricas.pdf</basename>
    </file>
    <file>
        <id_resource>1047</id_resource>
        <id_file>16610</id_file>
        <type>7</type>
        <dirname>1047</dirname>
        <basename>aliceealeidoscossenos.pdf</basename>
    </file>
    <file>
        <id_resource>1048</id_resource>
        <id_file>16613</id_file>
        <type>7</type>
        <dirname>1048</dirname>
        <basename>aliceeocossenodadiferencadearcos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1049</id_resource>
        <id_file>16616</id_file>
        <type>7</type>
        <dirname>1049</dirname>
        <basename>amuletomagico-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1050</id_resource>
        <id_file>16619</id_file>
        <type>7</type>
        <dirname>1050</dirname>
        <basename>aaparicao.pdf</basename>
    </file>
    <file>
        <id_resource>1051</id_resource>
        <id_file>16622</id_file>
        <type>7</type>
        <dirname>1051</dirname>
        <basename>artematematica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1052</id_resource>
        <id_file>16625</id_file>
        <type>7</type>
        <dirname>1052</dirname>
        <basename>atleticanoriograndense-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1053</id_resource>
        <id_file>16628</id_file>
        <type>7</type>
        <dirname>1053</dirname>
        <basename>UmaAventuraRPG-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1054</id_resource>
        <id_file>16631</id_file>
        <type>7</type>
        <dirname>1054</dirname>
        <basename>asaventurasderadix-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1055</id_resource>
        <id_file>16634</id_file>
        <type>7</type>
        <dirname>1055</dirname>
        <basename>bombonsagranel-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1056</id_resource>
        <id_file>16637</id_file>
        <type>7</type>
        <dirname>1056</dirname>
        <basename>brasileargentina.pdf</basename>
    </file>
    <file>
        <id_resource>1057</id_resource>
        <id_file>16640</id_file>
        <type>7</type>
        <dirname>1057</dirname>
        <basename>breverelatodofim-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1058</id_resource>
        <id_file>16643</id_file>
        <type>7</type>
        <dirname>1058</dirname>
        <basename>cacadoresdossonsdefibonacci.pdf</basename>
    </file>
    <file>
        <id_resource>1059</id_resource>
        <id_file>16646</id_file>
        <type>7</type>
        <dirname>1059</dirname>
        <basename>cadagraficonoseugalho-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1060</id_resource>
        <id_file>16649</id_file>
        <type>7</type>
        <dirname>1060</dirname>
        <basename>CaminhoCombaterDengue-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1061</id_resource>
        <id_file>16652</id_file>
        <type>7</type>
        <dirname>1061</dirname>
        <basename>umcaminhoparaocurral.pdf</basename>
    </file>
    <file>
        <id_resource>1062</id_resource>
        <id_file>16655</id_file>
        <type>7</type>
        <dirname>1062</dirname>
        <basename>caraoucoroa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1064</id_resource>
        <id_file>16661</id_file>
        <type>7</type>
        <dirname>1064</dirname>
        <basename>acarta-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1065</id_resource>
        <id_file>16664</id_file>
        <type>7</type>
        <dirname>1065</dirname>
        <basename>acartomante.pdf</basename>
    </file>
    <file>
        <id_resource>1066</id_resource>
        <id_file>16667</id_file>
        <type>7</type>
        <dirname>1066</dirname>
        <basename>umcertofatordeescala-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1067</id_resource>
        <id_file>16670</id_file>
        <type>7</type>
        <dirname>1067</dirname>
        <basename>acesaroqueedecesar-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1068</id_resource>
        <id_file>16673</id_file>
        <type>7</type>
        <dirname>1068</dirname>
        <basename>cloronapiscina-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1069</id_resource>
        <id_file>16676</id_file>
        <type>7</type>
        <dirname>1069</dirname>
        <basename>codigopascal.pdf</basename>
    </file>
    <file>
        <id_resource>1070</id_resource>
        <id_file>16679</id_file>
        <type>7</type>
        <dirname>1070</dirname>
        <basename>coisadepassarinho.pdf</basename>
    </file>
    <file>
        <id_resource>1071</id_resource>
        <id_file>16682</id_file>
        <type>7</type>
        <dirname>1071</dirname>
        <basename>coisasdoamor.pdf</basename>
    </file>
    <file>
        <id_resource>1072</id_resource>
        <id_file>16685</id_file>
        <type>7</type>
        <dirname>1072</dirname>
        <basename>colmeiaglobal-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1073</id_resource>
        <id_file>16688</id_file>
        <type>7</type>
        <dirname>1073</dirname>
        <basename>comendonumeros-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1074</id_resource>
        <id_file>16691</id_file>
        <type>7</type>
        <dirname>1074</dirname>
        <basename>acomunidade-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1076</id_resource>
        <id_file>16695</id_file>
        <type>7</type>
        <dirname>1076</dirname>
        <basename>cooperativadeleite.pdf</basename>
    </file>
    <file>
        <id_resource>1077</id_resource>
        <id_file>16698</id_file>
        <type>7</type>
        <dirname>1077</dirname>
        <basename>cozinhacientifica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1078</id_resource>
        <id_file>16701</id_file>
        <type>7</type>
        <dirname>1078</dirname>
        <basename>criadorecriatura-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1079</id_resource>
        <id_file>16704</id_file>
        <type>7</type>
        <dirname>1079</dirname>
        <basename>crimedaruadogasometro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1080</id_resource>
        <id_file>16707</id_file>
        <type>7</type>
        <dirname>1080</dirname>
        <basename>dancadosol-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1081</id_resource>
        <id_file>16710</id_file>
        <type>7</type>
        <dirname>1081</dirname>
        <basename>dancaembaralhamento-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1082</id_resource>
        <id_file>16713</id_file>
        <type>7</type>
        <dirname>1082</dirname>
        <basename>dadoibope-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1083</id_resource>
        <id_file>16716</id_file>
        <type>7</type>
        <dirname>1083</dirname>
        <basename>demalasprontas.pdf</basename>
    </file>
    <file>
        <id_resource>1084</id_resource>
        <id_file>16719</id_file>
        <type>7</type>
        <dirname>1084</dirname>
        <basename>desafiodascorrelacoesespurias.pdf</basename>
    </file>
    <file>
        <id_resource>1085</id_resource>
        <id_file>16722</id_file>
        <type>7</type>
        <dirname>1085</dirname>
        <basename>desejos.pdf</basename>
    </file>
    <file>
        <id_resource>1086</id_resource>
        <id_file>16725</id_file>
        <type>7</type>
        <dirname>1086</dirname>
        <basename>desenhandoondas.pdf</basename>
    </file>
    <file>
        <id_resource>1087</id_resource>
        <id_file>16728</id_file>
        <type>7</type>
        <dirname>1087</dirname>
        <basename>desvendandoocalendario-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1088</id_resource>
        <id_file>16731</id_file>
        <type>7</type>
        <dirname>1088</dirname>
        <basename>asdesventurasdamaejoana-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1089</id_resource>
        <id_file>16734</id_file>
        <type>7</type>
        <dirname>1089</dirname>
        <basename>odilemadoprisioneiro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1090</id_resource>
        <id_file>16737</id_file>
        <type>7</type>
        <dirname>1090</dirname>
        <basename>dinamicadegrupo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1091</id_resource>
        <id_file>16740</id_file>
        <type>7</type>
        <dirname>1091</dirname>
        <basename>direitosdoconsumidor.pdf</basename>
    </file>
    <file>
        <id_resource>1092</id_resource>
        <id_file>16743</id_file>
        <type>7</type>
        <dirname>1092</dirname>
        <basename>eagorajose-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1093</id_resource>
        <id_file>16746</id_file>
        <type>7</type>
        <dirname>1093</dirname>
        <basename>emequilibrio-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1094</id_resource>
        <id_file>16749</id_file>
        <type>7</type>
        <dirname>1094</dirname>
        <basename>embalagens-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1095</id_resource>
        <id_file>16752</id_file>
        <type>7</type>
        <dirname>1095</dirname>
        <basename>encontroinusitado-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1096</id_resource>
        <id_file>16755</id_file>
        <type>7</type>
        <dirname>1096</dirname>
        <basename>entrandopelotunel-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1097</id_resource>
        <id_file>16758</id_file>
        <type>7</type>
        <dirname>1097</dirname>
        <basename>essetaldebhaskara-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1098</id_resource>
        <id_file>16761</id_file>
        <type>7</type>
        <dirname>1098</dirname>
        <basename>aexperiencia-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1099</id_resource>
        <id_file>16764</id_file>
        <type>7</type>
        <dirname>1099</dirname>
        <basename>formulamagica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1100</id_resource>
        <id_file>16767</id_file>
        <type>7</type>
        <dirname>1100</dirname>
        <basename>gabaritosecreto.pdf</basename>
    </file>
    <file>
        <id_resource>1101</id_resource>
        <id_file>16770</id_file>
        <type>7</type>
        <dirname>1101</dirname>
        <basename>gasolinaoualcool-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1102</id_resource>
        <id_file>16773</id_file>
        <type>7</type>
        <dirname>1102</dirname>
        <basename>geodetetive-1-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1103</id_resource>
        <id_file>16776</id_file>
        <type>7</type>
        <dirname>1103</dirname>
        <basename>geodetetive2-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1104</id_resource>
        <id_file>16779</id_file>
        <type>7</type>
        <dirname>1104</dirname>
        <basename>geodetetive3-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1106</id_resource>
        <id_file>16783</id_file>
        <type>7</type>
        <dirname>1106</dirname>
        <basename>geodetetive5-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1107</id_resource>
        <id_file>16786</id_file>
        <type>7</type>
        <dirname>1107</dirname>
        <basename>geodetetive_6-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1108</id_resource>
        <id_file>16789</id_file>
        <type>7</type>
        <dirname>1108</dirname>
        <basename>ogolpe-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1109</id_resource>
        <id_file>16792</id_file>
        <type>7</type>
        <dirname>1109</dirname>
        <basename>grandehotel-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1110</id_resource>
        <id_file>16795</id_file>
        <type>7</type>
        <dirname>1110</dirname>
        <basename>grandehotel2-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1111</id_resource>
        <id_file>16798</id_file>
        <type>7</type>
        <dirname>1111</dirname>
        <basename>grilocantante-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1112</id_resource>
        <id_file>16801</id_file>
        <type>7</type>
        <dirname>1112</dirname>
        <basename>oguardadordeaguas.pdf</basename>
    </file>
    <file>
        <id_resource>1113</id_resource>
        <id_file>16804</id_file>
        <type>7</type>
        <dirname>1113</dirname>
        <basename>halloween-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1114</id_resource>
        <id_file>16807</id_file>
        <type>7</type>
        <dirname>1114</dirname>
        <basename>herancadefamilia-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1115</id_resource>
        <id_file>16810</id_file>
        <type>7</type>
        <dirname>1115</dirname>
        <basename>ahistoriademussaraf-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1116</id_resource>
        <id_file>16813</id_file>
        <type>7</type>
        <dirname>1116</dirname>
        <basename>hitdosbits-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1117</id_resource>
        <id_file>16816</id_file>
        <type>7</type>
        <dirname>1117</dirname>
        <basename>hoteldehilbert-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1118</id_resource>
        <id_file>16819</id_file>
        <type>7</type>
        <dirname>1118</dirname>
        <basename>huguinhozezinho-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1119</id_resource>
        <id_file>16822</id_file>
        <type>7</type>
        <dirname>1119</dirname>
        <basename>aincrivelpiritipocadaserra.pdf</basename>
    </file>
    <file>
        <id_resource>1120</id_resource>
        <id_file>16825</id_file>
        <type>7</type>
        <dirname>1120</dirname>
        <basename>osinfinitosdecantor-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1121</id_resource>
        <id_file>16828</id_file>
        <type>7</type>
        <dirname>1121</dirname>
        <basename>jardimdenumeros-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1122</id_resource>
        <id_file>16831</id_file>
        <type>7</type>
        <dirname>1122</dirname>
        <basename>guia_ojogodedadosdemozart.pdf</basename>
    </file>
    <file>
        <id_resource>1123</id_resource>
        <id_file>16834</id_file>
        <type>7</type>
        <dirname>1123</dirname>
        <basename>jogos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1124</id_resource>
        <id_file>16837</id_file>
        <type>7</type>
        <dirname>1124</dirname>
        <basename>jurosdivididosdividacrescente.pdf</basename>
    </file>
    <file>
        <id_resource>1125</id_resource>
        <id_file>16840</id_file>
        <type>7</type>
        <dirname>1125</dirname>
        <basename>lembrancasdesofia.pdf</basename>
    </file>
    <file>
        <id_resource>1126</id_resource>
        <id_file>16843</id_file>
        <type>7</type>
        <dirname>1126</dirname>
        <basename>lendadedido.pdf</basename>
    </file>
    <file>
        <id_resource>1127</id_resource>
        <id_file>16846</id_file>
        <type>7</type>
        <dirname>1127</dirname>
        <basename>logicadealice-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1128</id_resource>
        <id_file>16849</id_file>
        <type>7</type>
        <dirname>1128</dirname>
        <basename>aloiradobanheiro.pdf</basename>
    </file>
    <file>
        <id_resource>1129</id_resource>
        <id_file>16852</id_file>
        <type>7</type>
        <dirname>1129</dirname>
        <basename>luthierdeproporcoes.pdf</basename>
    </file>
    <file>
        <id_resource>1130</id_resource>
        <id_file>16855</id_file>
        <type>7</type>
        <dirname>1130</dirname>
        <basename>amae.pdf</basename>
    </file>
    <file>
        <id_resource>1131</id_resource>
        <id_file>16858</id_file>
        <type>7</type>
        <dirname>1131</dirname>
        <basename>omagicodasarabias.pdf</basename>
    </file>
    <file>
        <id_resource>1132</id_resource>
        <id_file>16861</id_file>
        <type>7</type>
        <dirname>1132</dirname>
        <basename>maldicaodapiramide-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1133</id_resource>
        <id_file>16864</id_file>
        <type>7</type>
        <dirname>1133</dirname>
        <basename>amancha.pdf</basename>
    </file>
    <file>
        <id_resource>1134</id_resource>
        <id_file>16867</id_file>
        <type>7</type>
        <dirname>1134</dirname>
        <basename>medindoaterra-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1135</id_resource>
        <id_file>16870</id_file>
        <type>7</type>
        <dirname>1135</dirname>
        <basename>musicaquaseporacaso-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1136</id_resource>
        <id_file>16873</id_file>
        <type>7</type>
        <dirname>1136</dirname>
        <basename>musicaquasesemcompositor-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1137</id_resource>
        <id_file>16876</id_file>
        <type>7</type>
        <dirname>1137</dirname>
        <basename>nacaudadocometa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1138</id_resource>
        <id_file>16879</id_file>
        <type>7</type>
        <dirname>1138</dirname>
        <basename>naturalmente.pdf</basename>
    </file>
    <file>
        <id_resource>1139</id_resource>
        <id_file>16882</id_file>
        <type>7</type>
        <dirname>1139</dirname>
        <basename>noitedeforro.pdf</basename>
    </file>
    <file>
        <id_resource>1140</id_resource>
        <id_file>16885</id_file>
        <type>7</type>
        <dirname>1140</dirname>
        <basename>osonho.pdf</basename>
    </file>
    <file>
        <id_resource>1141</id_resource>
        <id_file>16888</id_file>
        <type>7</type>
        <dirname>1141</dirname>
        <basename>osonhocontinua-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1142</id_resource>
        <id_file>16891</id_file>
        <type>7</type>
        <dirname>1142</dirname>
        <basename>osonhonaoacabou-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1143</id_resource>
        <id_file>16894</id_file>
        <type>7</type>
        <dirname>1143</dirname>
        <basename>oferendamusicaldebach-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1144</id_resource>
        <id_file>16897</id_file>
        <type>7</type>
        <dirname>1144</dirname>
        <basename>olhaosanduiche-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1145</id_resource>
        <id_file>16900</id_file>
        <type>7</type>
        <dirname>1145</dirname>
        <basename>osanguloseastorres-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1146</id_resource>
        <id_file>16903</id_file>
        <type>7</type>
        <dirname>1146</dirname>
        <basename>ossoduroderoer-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1147</id_resource>
        <id_file>16906</id_file>
        <type>7</type>
        <dirname>1147</dirname>
        <basename>overdose-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1148</id_resource>
        <id_file>16909</id_file>
        <type>7</type>
        <dirname>1148</dirname>
        <basename>pandemia-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1149</id_resource>
        <id_file>16912</id_file>
        <type>7</type>
        <dirname>1149</dirname>
        <basename>panquecasdadonagloria-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1150</id_resource>
        <id_file>16915</id_file>
        <type>7</type>
        <dirname>1150</dirname>
        <basename>paracorrerasaosilvestre.pdf</basename>
    </file>
    <file>
        <id_resource>1151</id_resource>
        <id_file>16918</id_file>
        <type>7</type>
        <dirname>1151</dirname>
        <basename>parasalvaromundo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1154</id_resource>
        <id_file>16927</id_file>
        <type>7</type>
        <dirname>1154</dirname>
        <basename>pelaslentesdamatematica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1155</id_resource>
        <id_file>16930</id_file>
        <type>7</type>
        <dirname>1155</dirname>
        <basename>perdidonoglobo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1156</id_resource>
        <id_file>16933</id_file>
        <type>7</type>
        <dirname>1156</dirname>
        <basename>poema3quebracabecas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1157</id_resource>
        <id_file>16936</id_file>
        <type>7</type>
        <dirname>1157</dirname>
        <basename>umpontodevista-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1158</id_resource>
        <id_file>16939</id_file>
        <type>7</type>
        <dirname>1158</dirname>
        <basename>praladebagda-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1159</id_resource>
        <id_file>16942</id_file>
        <type>7</type>
        <dirname>1159</dirname>
        <basename>oprincipedesofia-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1160</id_resource>
        <id_file>16945</id_file>
        <type>7</type>
        <dirname>1160</dirname>
        <basename>oproblemadacerca-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1161</id_resource>
        <id_file>16948</id_file>
        <type>7</type>
        <dirname>1161</dirname>
        <basename>procurandoxenakis-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1162</id_resource>
        <id_file>16951</id_file>
        <type>7</type>
        <dirname>1162</dirname>
        <basename>provadealternativas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1164</id_resource>
        <id_file>16957</id_file>
        <type>7</type>
        <dirname>1164</dirname>
        <basename>qual-o-melhor-caminho-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1165</id_resource>
        <id_file>16960</id_file>
        <type>7</type>
        <dirname>1165</dirname>
        <basename>quesaco-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1166</id_resource>
        <id_file>16963</id_file>
        <type>7</type>
        <dirname>1166</dirname>
        <basename>queaforcaestejacomvoce.pdf</basename>
    </file>
    <file>
        <id_resource>1167</id_resource>
        <id_file>16966</id_file>
        <type>7</type>
        <dirname>1167</dirname>
        <basename>quemquersermilionario-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1168</id_resource>
        <id_file>16969</id_file>
        <type>7</type>
        <dirname>1168</dirname>
        <basename>razaodosirracionais-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1169</id_resource>
        <id_file>16972</id_file>
        <type>7</type>
        <dirname>1169</dirname>
        <basename>revanchedealice-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1170</id_resource>
        <id_file>16975</id_file>
        <type>7</type>
        <dirname>1170</dirname>
        <basename>revendoamoratoria-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1171</id_resource>
        <id_file>16978</id_file>
        <type>7</type>
        <dirname>1171</dirname>
        <basename>rodaroda-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1172</id_resource>
        <id_file>16981</id_file>
        <type>7</type>
        <dirname>1172</dirname>
        <basename>RodadeSamba-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1173</id_resource>
        <id_file>16984</id_file>
        <type>7</type>
        <dirname>1173</dirname>
        <basename>rodadosonho-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1174</id_resource>
        <id_file>16987</id_file>
        <type>7</type>
        <dirname>1174</dirname>
        <basename>salvadorhipocondriaco-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1175</id_resource>
        <id_file>16990</id_file>
        <type>7</type>
        <dirname>1175</dirname>
        <basename>seeufossevoce.pdf</basename>
    </file>
    <file>
        <id_resource>1176</id_resource>
        <id_file>16993</id_file>
        <type>7</type>
        <dirname>1176</dirname>
        <basename>semdiscriminacao.pdf</basename>
    </file>
    <file>
        <id_resource>1177</id_resource>
        <id_file>16996</id_file>
        <type>7</type>
        <dirname>1177</dirname>
        <basename>sinfoniadepoliedros.pdf</basename>
    </file>
    <file>
        <id_resource>1178</id_resource>
        <id_file>16999</id_file>
        <type>7</type>
        <dirname>1178</dirname>
        <basename>sonhodourado-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1179</id_resource>
        <id_file>17002</id_file>
        <type>7</type>
        <dirname>1179</dirname>
        <basename>surpresaparaoscalouros-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1180</id_resource>
        <id_file>17005</id_file>
        <type>7</type>
        <dirname>1180</dirname>
        <basename>ossuspeitos.pdf</basename>
    </file>
    <file>
        <id_resource>1181</id_resource>
        <id_file>17008</id_file>
        <type>7</type>
        <dirname>1181</dirname>
        <basename>temposdeguerra-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1182</id_resource>
        <id_file>17011</id_file>
        <type>7</type>
        <dirname>1182</dirname>
        <basename>terremotobrasileiro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1183</id_resource>
        <id_file>17014</id_file>
        <type>7</type>
        <dirname>1183</dirname>
        <basename>tesourocartesiano-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1184</id_resource>
        <id_file>17017</id_file>
        <type>7</type>
        <dirname>1184</dirname>
        <basename>testedegravidez-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1185</id_resource>
        <id_file>17020</id_file>
        <type>7</type>
        <dirname>1185</dirname>
        <basename>triangularehpreciso-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1186</id_resource>
        <id_file>17023</id_file>
        <type>7</type>
        <dirname>1186</dirname>
        <basename>tudoquevocesemprequisperguntar-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1187</id_resource>
        <id_file>17026</id_file>
        <type>7</type>
        <dirname>1187</dirname>
        <basename>umsonhocomplexo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1188</id_resource>
        <id_file>17029</id_file>
        <type>7</type>
        <dirname>1188</dirname>
        <basename>vocedissecristalografia-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1189</id_resource>
        <id_file>17032</id_file>
        <type>7</type>
        <dirname>1189</dirname>
        <basename>avelhahistoriadasmultidoes-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1190</id_resource>
        <id_file>17035</id_file>
        <type>7</type>
        <dirname>1190</dirname>
        <basename>vendasegura-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1191</id_resource>
        <id_file>17038</id_file>
        <type>7</type>
        <dirname>1191</dirname>
        <basename>voudetaxi-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1192</id_resource>
        <id_file>17041</id_file>
        <type>7</type>
        <dirname>1192</dirname>
        <basename>vozdointerior.pdf</basename>
    </file>
    <file>
        <id_resource>1193</id_resource>
        <id_file>17044</id_file>
        <type>7</type>
        <dirname>1193</dirname>
        <basename>xequemate-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1194</id_resource>
        <id_file>17047</id_file>
        <type>7</type>
        <dirname>1194</dirname>
        <basename>administradora_contador.pdf</basename>
    </file>
    <file>
        <id_resource>1195</id_resource>
        <id_file>17050</id_file>
        <type>7</type>
        <dirname>1195</dirname>
        <basename>advogado_engenheiroeletrico.pdf</basename>
    </file>
    <file>
        <id_resource>1196</id_resource>
        <id_file>17053</id_file>
        <type>7</type>
        <dirname>1196</dirname>
        <basename>agrimensorgeologo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1197</id_resource>
        <id_file>17056</id_file>
        <type>7</type>
        <dirname>1197</dirname>
        <basename>agronomo_fisoterapeuta.pdf</basename>
    </file>
    <file>
        <id_resource>1198</id_resource>
        <id_file>17059</id_file>
        <type>7</type>
        <dirname>1198</dirname>
        <basename>analistadesistema_engenheirodacomputacao.pdf</basename>
    </file>
    <file>
        <id_resource>1199</id_resource>
        <id_file>17062</id_file>
        <type>7</type>
        <dirname>1199</dirname>
        <basename>arquitetoengenheirocivil-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1200</id_resource>
        <id_file>17065</id_file>
        <type>7</type>
        <dirname>1200</dirname>
        <basename>artistaplastico_designergrafico-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1201</id_resource>
        <id_file>17068</id_file>
        <type>7</type>
        <dirname>1201</dirname>
        <basename>astronomo_fisico.pdf</basename>
    </file>
    <file>
        <id_resource>1202</id_resource>
        <id_file>17071</id_file>
        <type>7</type>
        <dirname>1202</dirname>
        <basename>atuario_estatistica.pdf</basename>
    </file>
    <file>
        <id_resource>1203</id_resource>
        <id_file>17074</id_file>
        <type>7</type>
        <dirname>1203</dirname>
        <basename>biologonutricionista-gua.pdf</basename>
    </file>
    <file>
        <id_resource>1204</id_resource>
        <id_file>17077</id_file>
        <type>7</type>
        <dirname>1204</dirname>
        <basename>chefdecozinha_estilista-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1205</id_resource>
        <id_file>17080</id_file>
        <type>7</type>
        <dirname>1205</dirname>
        <basename>comerciantecorretordeimoveis.pdf</basename>
    </file>
    <file>
        <id_resource>1206</id_resource>
        <id_file>17083</id_file>
        <type>7</type>
        <dirname>1206</dirname>
        <basename>controladordevoo_pilotohelicoptero-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1207</id_resource>
        <id_file>17086</id_file>
        <type>7</type>
        <dirname>1207</dirname>
        <basename>economista_comercioexterior.pdf</basename>
    </file>
    <file>
        <id_resource>1208</id_resource>
        <id_file>17089</id_file>
        <type>7</type>
        <dirname>1208</dirname>
        <basename>engenheiradealimentos_quimico.pdf</basename>
    </file>
    <file>
        <id_resource>1209</id_resource>
        <id_file>17092</id_file>
        <type>7</type>
        <dirname>1209</dirname>
        <basename>esportista_fotografo.pdf</basename>
    </file>
    <file>
        <id_resource>1210</id_resource>
        <id_file>17095</id_file>
        <type>7</type>
        <dirname>1210</dirname>
        <basename>farmaceutica_produtoradeeventos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1211</id_resource>
        <id_file>17098</id_file>
        <type>7</type>
        <dirname>1211</dirname>
        <basename>hotelarialogistica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1212</id_resource>
        <id_file>17101</id_file>
        <type>7</type>
        <dirname>1212</dirname>
        <basename>matematico_musio.pdf</basename>
    </file>
    <file>
        <id_resource>1213</id_resource>
        <id_file>17104</id_file>
        <type>7</type>
        <dirname>1213</dirname>
        <basename>meteorologista_turismologa.pdf</basename>
    </file>
    <file>
        <id_resource>1214</id_resource>
        <id_file>17107</id_file>
        <type>7</type>
        <dirname>1214</dirname>
        <basename>odontologiamedicina-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1215</id_resource>
        <id_file>17110</id_file>
        <type>7</type>
        <dirname>1215</dirname>
        <basename>produtordecinema_publicitario.pdf</basename>
    </file>
    <file>
        <id_resource>1216</id_resource>
        <id_file>17113</id_file>
        <type>7</type>
        <dirname>1216</dirname>
        <basename>professoruniversitario_bancaria.pdf</basename>
    </file>
    <file>
        <id_resource>1217</id_resource>
        <id_file>17116</id_file>
        <type>7</type>
        <dirname>1217</dirname>
        <basename>secretaria_engenheirodetelecomunicacoes.pdf</basename>
    </file>
    <file>
        <id_resource>1218</id_resource>
        <id_file>17119</id_file>
        <type>7</type>
        <dirname>1218</dirname>
        <basename>zootecniaveterinaria-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1251</id_resource>
        <id_file>17211</id_file>
        <type>7</type>
        <dirname>1251</dirname>
        <basename>embaralhandosherlockholmes-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1254</id_resource>
        <id_file>17217</id_file>
        <type>7</type>
        <dirname>1254</dirname>
        <basename>minhocando-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1255</id_resource>
        <id_file>17221</id_file>
        <type>7</type>
        <dirname>1255</dirname>
        <basename>mundosimaginarios-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1257</id_resource>
        <id_file>17226</id_file>
        <type>7</type>
        <dirname>1257</dirname>
        <basename>pensandoemprogressaogeometrica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1262</id_resource>
        <id_file>17234</id_file>
        <type>7</type>
        <dirname>1262</dirname>
        <basename>6grausseparacao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1263</id_resource>
        <id_file>17238</id_file>
        <type>7</type>
        <dirname>1263</dirname>
        <basename>h1n1-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1264</id_resource>
        <id_file>17242</id_file>
        <type>7</type>
        <dirname>1264</dirname>
        <basename>tremores-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1265</id_resource>
        <id_file>17246</id_file>
        <type>7</type>
        <dirname>1265</dirname>
        <basename>universo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1266</id_resource>
        <id_file>17250</id_file>
        <type>7</type>
        <dirname>1266</dirname>
        <basename>violenciamatematica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1267</id_resource>
        <id_file>17254</id_file>
        <type>7</type>
        <dirname>1267</dirname>
        <basename>animaisnanatureza-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1268</id_resource>
        <id_file>17258</id_file>
        <type>7</type>
        <dirname>1268</dirname>
        <basename>combinacao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1269</id_resource>
        <id_file>17262</id_file>
        <type>7</type>
        <dirname>1269</dirname>
        <basename>combustiveis-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1270</id_resource>
        <id_file>17266</id_file>
        <type>7</type>
        <dirname>1270</dirname>
        <basename>estimativasdocorpohumano-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1271</id_resource>
        <id_file>17270</id_file>
        <type>7</type>
        <dirname>1271</dirname>
        <basename>distancias-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1272</id_resource>
        <id_file>17274</id_file>
        <type>7</type>
        <dirname>1272</dirname>
        <basename>esferas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1273</id_resource>
        <id_file>17278</id_file>
        <type>7</type>
        <dirname>1273</dirname>
        <basename>lixo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1274</id_resource>
        <id_file>17282</id_file>
        <type>7</type>
        <dirname>1274</dirname>
        <basename>massaeidade-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1275</id_resource>
        <id_file>17286</id_file>
        <type>7</type>
        <dirname>1275</dirname>
        <basename>pizzafeijao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1276</id_resource>
        <id_file>17290</id_file>
        <type>7</type>
        <dirname>1276</dirname>
        <basename>infinito1-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1277</id_resource>
        <id_file>17294</id_file>
        <type>7</type>
        <dirname>1277</dirname>
        <basename>infinito2-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1278</id_resource>
        <id_file>17298</id_file>
        <type>7</type>
        <dirname>1278</dirname>
        <basename>intrigascubicas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1279</id_resource>
        <id_file>17302</id_file>
        <type>7</type>
        <dirname>1279</dirname>
        <basename>acriacaodoslogaritimos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1280</id_resource>
        <id_file>17306</id_file>
        <type>7</type>
        <dirname>1280</dirname>
        <basename>negativos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1281</id_resource>
        <id_file>17310</id_file>
        <type>7</type>
        <dirname>1281</dirname>
        <basename>newtonnumeros-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1282</id_resource>
        <id_file>17314</id_file>
        <type>7</type>
        <dirname>1282</dirname>
        <basename>operacomplexa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1283</id_resource>
        <id_file>17318</id_file>
        <type>7</type>
        <dirname>1283</dirname>
        <basename>quatro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1284</id_resource>
        <id_file>17322</id_file>
        <type>7</type>
        <dirname>1284</dirname>
        <basename>senos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1285</id_resource>
        <id_file>17326</id_file>
        <type>7</type>
        <dirname>1285</dirname>
        <basename>zerosimmascomorgulho-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1288</id_resource>
        <id_file>17332</id_file>
        <type>7</type>
        <dirname>1288</dirname>
        <basename>margemdeerro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1291</id_resource>
        <id_file>17338</id_file>
        <type>7</type>
        <dirname>1291</dirname>
        <basename>hipotenusa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1294</id_resource>
        <id_file>17344</id_file>
        <type>7</type>
        <dirname>1294</dirname>
        <basename>paralelogramo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1295</id_resource>
        <id_file>17348</id_file>
        <type>7</type>
        <dirname>1295</dirname>
        <basename>permutacao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1296</id_resource>
        <id_file>17352</id_file>
        <type>7</type>
        <dirname>1296</dirname>
        <basename>poliedro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1297</id_resource>
        <id_file>17356</id_file>
        <type>7</type>
        <dirname>1297</dirname>
        <basename>polinomio-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1298</id_resource>
        <id_file>17360</id_file>
        <type>7</type>
        <dirname>1298</dirname>
        <basename>primo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1299</id_resource>
        <id_file>17364</id_file>
        <type>7</type>
        <dirname>1299</dirname>
        <basename>radiano-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1301</id_resource>
        <id_file>17372</id_file>
        <type>7</type>
        <dirname>1301</dirname>
        <basename>invejadosaneisdesaturno-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1302</id_resource>
        <id_file>17376</id_file>
        <type>7</type>
        <dirname>1302</dirname>
        <basename>bifesnachapa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1303</id_resource>
        <id_file>17380</id_file>
        <type>7</type>
        <dirname>1303</dirname>
        <basename>calcadas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1304</id_resource>
        <id_file>17384</id_file>
        <type>7</type>
        <dirname>1304</dirname>
        <basename>camelos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1305</id_resource>
        <id_file>17388</id_file>
        <type>7</type>
        <dirname>1305</dirname>
        <basename>caminhoesdeacucar-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1306</id_resource>
        <id_file>17392</id_file>
        <type>7</type>
        <dirname>1306</dirname>
        <basename>comodescobriraverdade-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1308</id_resource>
        <id_file>17397</id_file>
        <type>7</type>
        <dirname>1308</dirname>
        <basename>contagemdepassos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1309</id_resource>
        <id_file>17401</id_file>
        <type>7</type>
        <dirname>1309</dirname>
        <basename>dadosnaotransitivos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1310</id_resource>
        <id_file>17405</id_file>
        <type>7</type>
        <dirname>1310</dirname>
        <basename>diferencadosprimos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1311</id_resource>
        <id_file>17409</id_file>
        <type>7</type>
        <dirname>1311</dirname>
        <basename>comodividirumbolo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1312</id_resource>
        <id_file>17417</id_file>
        <type>7</type>
        <dirname>1312</dirname>
        <basename>formacaotriangular-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1315</id_resource>
        <id_file>17423</id_file>
        <type>7</type>
        <dirname>1315</dirname>
        <basename>mediasqueinteressam-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1317</id_resource>
        <id_file>17428</id_file>
        <type>7</type>
        <dirname>1317</dirname>
        <basename>permutacao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1319</id_resource>
        <id_file>17433</id_file>
        <type>7</type>
        <dirname>1319</dirname>
        <basename>herancaetestamentoruins-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1320</id_resource>
        <id_file>17437</id_file>
        <type>7</type>
        <dirname>1320</dirname>
        <basename>subconjuntos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1321</id_resource>
        <id_file>17441</id_file>
        <type>7</type>
        <dirname>1321</dirname>
        <basename>tamanhodamesa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1322</id_resource>
        <id_file>17445</id_file>
        <type>7</type>
        <dirname>1322</dirname>
        <basename>tamanhodaterra-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1323</id_resource>
        <id_file>17449</id_file>
        <type>7</type>
        <dirname>1323</dirname>
        <basename>TitiusBode-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1325</id_resource>
        <id_file>17454</id_file>
        <type>7</type>
        <dirname>1325</dirname>
        <basename>tudocomecaempizza-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1326</id_resource>
        <id_file>17458</id_file>
        <type>7</type>
        <dirname>1326</dirname>
        <basename>volumeconecilindro-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1328</id_resource>
        <id_file>17466</id_file>
        <type>7</type>
        <dirname>1328</dirname>
        <basename>oproblema3xmais1-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1329</id_resource>
        <id_file>17470</id_file>
        <type>7</type>
        <dirname>1329</dirname>
        <basename>6grausdeconexoes-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1330</id_resource>
        <id_file>17474</id_file>
        <type>7</type>
        <dirname>1330</dirname>
        <basename>celulastronco-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1362</id_resource>
        <id_file>17478</id_file>
        <type>7</type>
        <dirname>1362</dirname>
        <basename>conjecturadegoldbach-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1331</id_resource>
        <id_file>17482</id_file>
        <type>7</type>
        <dirname>1331</dirname>
        <basename>conteudodouniverso-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1332</id_resource>
        <id_file>17486</id_file>
        <type>7</type>
        <dirname>1332</dirname>
        <basename>curvadosino-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1333</id_resource>
        <id_file>17490</id_file>
        <type>7</type>
        <dirname>1333</dirname>
        <basename>pesodaduvida-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1334</id_resource>
        <id_file>17494</id_file>
        <type>7</type>
        <dirname>1334</dirname>
        <basename>esferacabelonano-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1335</id_resource>
        <id_file>17498</id_file>
        <type>7</type>
        <dirname>1335</dirname>
        <basename>exoplanetas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1336</id_resource>
        <id_file>17502</id_file>
        <type>7</type>
        <dirname>1336</dirname>
        <basename>formigas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1337</id_resource>
        <id_file>17506</id_file>
        <type>7</type>
        <dirname>1337</dirname>
        <basename>genoma-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1338</id_resource>
        <id_file>17510</id_file>
        <type>7</type>
        <dirname>1338</dirname>
        <basename>gravideznaadolescenciaa-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1339</id_resource>
        <id_file>17514</id_file>
        <type>7</type>
        <dirname>1339</dirname>
        <basename>horariodeverao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1340</id_resource>
        <id_file>17518</id_file>
        <type>7</type>
        <dirname>1340</dirname>
        <basename>imobesidadeiac-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1342</id_resource>
        <id_file>17523</id_file>
        <type>7</type>
        <dirname>1342</dirname>
        <basename>maquinasdotempo-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1343</id_resource>
        <id_file>17527</id_file>
        <type>7</type>
        <dirname>1343</dirname>
        <basename>mudancasclimaticasepitagoras-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1344</id_resource>
        <id_file>17531</id_file>
        <type>7</type>
        <dirname>1344</dirname>
        <basename>noslacos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1345</id_resource>
        <id_file>17535</id_file>
        <type>7</type>
        <dirname>1345</dirname>
        <basename>numerosprimos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1346</id_resource>
        <id_file>17539</id_file>
        <type>7</type>
        <dirname>1346</dirname>
        <basename>previsoesolimpiadas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1347</id_resource>
        <id_file>17543</id_file>
        <type>7</type>
        <dirname>1347</dirname>
        <basename>primosgemeos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1348</id_resource>
        <id_file>17547</id_file>
        <type>7</type>
        <dirname>1348</dirname>
        <basename>redesdedistribuicao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1350</id_resource>
        <id_file>17552</id_file>
        <type>7</type>
        <dirname>1350</dirname>
        <basename>sexodobebe-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1352</id_resource>
        <id_file>17557</id_file>
        <type>7</type>
        <dirname>1352</dirname>
        <basename>tamanhodoproton-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1353</id_resource>
        <id_file>17561</id_file>
        <type>7</type>
        <dirname>1353</dirname>
        <basename>tempestadessolares-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1354</id_resource>
        <id_file>17565</id_file>
        <type>7</type>
        <dirname>1354</dirname>
        <basename>terremotos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1355</id_resource>
        <id_file>17569</id_file>
        <type>7</type>
        <dirname>1355</dirname>
        <basename>tijolodeeuler-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1356</id_resource>
        <id_file>17573</id_file>
        <type>7</type>
        <dirname>1356</dirname>
        <basename>moleculasemtitan-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1357</id_resource>
        <id_file>17577</id_file>
        <type>7</type>
        <dirname>1357</dirname>
        <basename>tubaroesfractais-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1358</id_resource>
        <id_file>17581</id_file>
        <type>7</type>
        <dirname>1358</dirname>
        <basename>utildnainutil-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1359</id_resource>
        <id_file>17585</id_file>
        <type>7</type>
        <dirname>1359</dirname>
        <basename>venusetrigonometria-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1364</id_resource>
        <id_file>17600</id_file>
        <type>7</type>
        <dirname>1364</dirname>
        <basename>atividadeavalanches---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1377</id_resource>
        <id_file>17703</id_file>
        <type>7</type>
        <dirname>1377</dirname>
        <basename>verao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1378</id_resource>
        <id_file>17711</id_file>
        <type>7</type>
        <dirname>1378</dirname>
        <basename>escalasdetemperatura-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1252</id_resource>
        <id_file>17727</id_file>
        <type>7</type>
        <dirname>1252</dirname>
        <basename>historiadaestatistica-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1253</id_resource>
        <id_file>17731</id_file>
        <type>7</type>
        <dirname>1253</dirname>
        <basename>historiadaprobabilidade-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1382</id_resource>
        <id_file>17734</id_file>
        <type>7</type>
        <dirname>1382</dirname>
        <basename>caixa_de_papel---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1384</id_resource>
        <id_file>17740</id_file>
        <type>7</type>
        <dirname>1384</dirname>
        <basename>montanhas_geometricas---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1385</id_resource>
        <id_file>17743</id_file>
        <type>7</type>
        <dirname>1385</dirname>
        <basename>padroes_no_plano---guia_do_professor.pdf</basename>
    </file>
    <file>
        <id_resource>1163</id_resource>
        <id_file>17850</id_file>
        <type>7</type>
        <dirname>1163</dirname>
        <basename>quadra_poliesportiva-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1258</id_resource>
        <id_file>17854</id_file>
        <type>7</type>
        <dirname>1258</dirname>
        <basename>pontes-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1261</id_resource>
        <id_file>17882</id_file>
        <type>7</type>
        <dirname>1261</dirname>
        <basename>verdadementira-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1293</id_resource>
        <id_file>17886</id_file>
        <type>7</type>
        <dirname>1293</dirname>
        <basename>guia_parabola.pdf</basename>
    </file>
    <file>
        <id_resource>1292</id_resource>
        <id_file>17890</id_file>
        <type>7</type>
        <dirname>1292</dirname>
        <basename>guia-logaritmo.pdf</basename>
    </file>
    <file>
        <id_resource>1289</id_resource>
        <id_file>17894</id_file>
        <type>7</type>
        <dirname>1289</dirname>
        <basename>guia-exponencial.pdf</basename>
    </file>
    <file>
        <id_resource>1287</id_resource>
        <id_file>17898</id_file>
        <type>7</type>
        <dirname>1287</dirname>
        <basename>guia_elipse.pdf</basename>
    </file>
    <file>
        <id_resource>1105</id_resource>
        <id_file>17902</id_file>
        <type>7</type>
        <dirname>1105</dirname>
        <basename>geodetetive4-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1075</id_resource>
        <id_file>17905</id_file>
        <type>7</type>
        <dirname>1075</dirname>
        <basename>conclusoesprecipitadas-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1256</id_resource>
        <id_file>17909</id_file>
        <type>7</type>
        <dirname>1256</dirname>
        <basename>pensandoemprogressaoaritmetica - guia.pdf</basename>
    </file>
    <file>
        <id_resource>1313</id_resource>
        <id_file>17913</id_file>
        <type>7</type>
        <dirname>1313</dirname>
        <basename>fraude171.pdf</basename>
    </file>
    <file>
        <id_resource>1314</id_resource>
        <id_file>17917</id_file>
        <type>7</type>
        <dirname>1314</dirname>
        <basename>gasolinaadulterada.pdf</basename>
    </file>
    <file>
        <id_resource>1316</id_resource>
        <id_file>17921</id_file>
        <type>7</type>
        <dirname>1316</dirname>
        <basename>mortosvivos.pdf</basename>
    </file>
    <file>
        <id_resource>1290</id_resource>
        <id_file>17926</id_file>
        <type>7</type>
        <dirname>1290</dirname>
        <basename>guia_hiperbole.pdf</basename>
    </file>
    <file>
        <id_resource>1318</id_resource>
        <id_file>17930</id_file>
        <type>7</type>
        <dirname>1318</dirname>
        <basename>guia-pesoextra.pdf</basename>
    </file>
    <file>
        <id_resource>1307</id_resource>
        <id_file>17934</id_file>
        <type>7</type>
        <dirname>1307</dirname>
        <basename>quantocustaaenergia-gua.pdf</basename>
    </file>
    <file>
        <id_resource>1324</id_resource>
        <id_file>17942</id_file>
        <type>7</type>
        <dirname>1324</dirname>
        <basename>guia-trianguloimpar.pdf</basename>
    </file>
    <file>
        <id_resource>1259</id_resource>
        <id_file>17946</id_file>
        <type>7</type>
        <dirname>1259</dirname>
        <basename>problemasgregos-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1260</id_resource>
        <id_file>17950</id_file>
        <type>7</type>
        <dirname>1260</dirname>
        <basename>vampiros-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1341</id_resource>
        <id_file>17954</id_file>
        <type>7</type>
        <dirname>1341</dirname>
        <basename>infinitosracionais-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1351</id_resource>
        <id_file>17958</id_file>
        <type>7</type>
        <dirname>1351</dirname>
        <basename>solidosconvexoseuler-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1360</id_resource>
        <id_file>17962</id_file>
        <type>7</type>
        <dirname>1360</dirname>
        <basename>vulcoes-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1286</id_resource>
        <id_file>17970</id_file>
        <type>7</type>
        <dirname>1286</dirname>
        <basename>guia-baricentro.pdf</basename>
    </file>
    <file>
        <id_resource>1153</id_resource>
        <id_file>17979</id_file>
        <type>7</type>
        <dirname>1153</dirname>
        <basename>apartedoleao-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1327</id_resource>
        <id_file>18009</id_file>
        <type>7</type>
        <dirname>1327</dirname>
        <basename>37porcentonamorados-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1063</id_resource>
        <id_file>18103</id_file>
        <type>7</type>
        <dirname>1063</dirname>
        <basename>carroflex-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1387</id_resource>
        <id_file>18110</id_file>
        <type>7</type>
        <dirname>1387</dirname>
        <basename>descobrindoalgoritmoGuido-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1152</id_resource>
        <id_file>18137</id_file>
        <type>7</type>
        <dirname>1152</dirname>
        <basename>aliceparadoxoformalizaco-guia.pdf</basename>
    </file>
    <file>
        <id_resource>1251</id_resource>
        <id_file>17209</id_file>
        <type>8</type>
        <dirname>1251</dirname>
        <basename>MAT 1.4-SEQUENCIA DE FIBONACCI-220310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1254</id_resource>
        <id_file>17215</id_file>
        <type>8</type>
        <dirname>1254</dirname>
        <basename>MAT 1.9-SERIE CULTURA-MINHOCANDO-280810-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1255</id_resource>
        <id_file>17219</id_file>
        <type>8</type>
        <dirname>1255</dirname>
        <basename>MAT 1.6-JACKIL E MR HIDE-060611-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1257</id_resource>
        <id_file>17224</id_file>
        <type>8</type>
        <dirname>1257</dirname>
        <basename>MAT 1.3-PROGRESSAO GEOMETRICA-230310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1262</id_resource>
        <id_file>17232</id_file>
        <type>8</type>
        <dirname>1262</dirname>
        <basename>MAT 7.1-SEIS GRAUS DE SEPARACAO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1263</id_resource>
        <id_file>17236</id_file>
        <type>8</type>
        <dirname>1263</dirname>
        <basename>MAT 7.3-H1N1-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1264</id_resource>
        <id_file>17240</id_file>
        <type>8</type>
        <dirname>1264</dirname>
        <basename>MAT 7.4-TERREMOTOS-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1265</id_resource>
        <id_file>17244</id_file>
        <type>8</type>
        <dirname>1265</dirname>
        <basename>MAT 7.2-CONTEUDO DO UNIVERSO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1266</id_resource>
        <id_file>17248</id_file>
        <type>8</type>
        <dirname>1266</dirname>
        <basename>MAT 7.5-VIOLENCIA-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1267</id_resource>
        <id_file>17252</id_file>
        <type>8</type>
        <dirname>1267</dirname>
        <basename>MAT 2.1-ANIMAIS NATUREZA-160310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1268</id_resource>
        <id_file>17256</id_file>
        <type>8</type>
        <dirname>1268</dirname>
        <basename>MAT 2.10-COMBINACAO-060510-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1269</id_resource>
        <id_file>17260</id_file>
        <type>8</type>
        <dirname>1269</dirname>
        <basename>MAT 2.4-COMBUSTIVEL-120110-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1270</id_resource>
        <id_file>17264</id_file>
        <type>8</type>
        <dirname>1270</dirname>
        <basename>MAT 2.2-BATIMENTOS E CABELO-160310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1271</id_resource>
        <id_file>17268</id_file>
        <type>8</type>
        <dirname>1271</dirname>
        <basename>MAT 2.5-DISTANCIAS-030210-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1272</id_resource>
        <id_file>17272</id_file>
        <type>8</type>
        <dirname>1272</dirname>
        <basename>MAT 2.6-ESFERAS-120110-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1273</id_resource>
        <id_file>17276</id_file>
        <type>8</type>
        <dirname>1273</dirname>
        <basename>MAT 2.7-ESTIMATIVA LIXO-050510-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1274</id_resource>
        <id_file>17280</id_file>
        <type>8</type>
        <dirname>1274</dirname>
        <basename>MAT 2.8-MASSA E IDADE-190310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1275</id_resource>
        <id_file>17284</id_file>
        <type>8</type>
        <dirname>1275</dirname>
        <basename>MAT 2.9-PIZZA E FEIJAO-190310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1276</id_resource>
        <id_file>17288</id_file>
        <type>8</type>
        <dirname>1276</dirname>
        <basename>MAT 5.1-INFINITO 1-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1277</id_resource>
        <id_file>17292</id_file>
        <type>8</type>
        <dirname>1277</dirname>
        <basename>MAT 5.2-INFINITO 2-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1278</id_resource>
        <id_file>17296</id_file>
        <type>8</type>
        <dirname>1278</dirname>
        <basename>MAT 5.9-EQUACOES CUBICAS-100511-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1279</id_resource>
        <id_file>17300</id_file>
        <type>8</type>
        <dirname>1279</dirname>
        <basename>MAT 5.8-CRIACAO DE LOGARITMOS-160610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1280</id_resource>
        <id_file>17304</id_file>
        <type>8</type>
        <dirname>1280</dirname>
        <basename>MAT 5.3-NEGATIVOS-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1281</id_resource>
        <id_file>17308</id_file>
        <type>8</type>
        <dirname>1281</dirname>
        <basename>MAT 5.4-NEWTON E OS NUMEROS-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1282</id_resource>
        <id_file>17312</id_file>
        <type>8</type>
        <dirname>1282</dirname>
        <basename>MAT 5.6-COMPLEXOS-160610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1283</id_resource>
        <id_file>17316</id_file>
        <type>8</type>
        <dirname>1283</dirname>
        <basename>MAT 5.10-QUATRO E UM QUADRADO-250411-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1284</id_resource>
        <id_file>17320</id_file>
        <type>8</type>
        <dirname>1284</dirname>
        <basename>MAT 5.7-SENOS-090610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1285</id_resource>
        <id_file>17324</id_file>
        <type>8</type>
        <dirname>1285</dirname>
        <basename>MAT 5.5-INVENCAO DO ZERO-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1288</id_resource>
        <id_file>17330</id_file>
        <type>8</type>
        <dirname>1288</dirname>
        <basename>MAT 3.13-MARGEM DE ERRO-160610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1291</id_resource>
        <id_file>17336</id_file>
        <type>8</type>
        <dirname>1291</dirname>
        <basename>MAT 3.5-O QUE E HIPOTENUSA-200310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1294</id_resource>
        <id_file>17342</id_file>
        <type>8</type>
        <dirname>1294</dirname>
        <basename>MAT 3.9-O QUE E PARALELOGRAMO-230310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1295</id_resource>
        <id_file>17346</id_file>
        <type>8</type>
        <dirname>1295</dirname>
        <basename>MAT 3.14-PERMUTACAO-160610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1296</id_resource>
        <id_file>17350</id_file>
        <type>8</type>
        <dirname>1296</dirname>
        <basename>MAT 3.10-O QUE E POLIEDRO-080910-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1297</id_resource>
        <id_file>17354</id_file>
        <type>8</type>
        <dirname>1297</dirname>
        <basename>MAT 3.11-O QUE E POLINOMIO-230310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1298</id_resource>
        <id_file>17358</id_file>
        <type>8</type>
        <dirname>1298</dirname>
        <basename>MAT 3.7-O QUE E NUMERO PRIMO-250310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1299</id_resource>
        <id_file>17362</id_file>
        <type>8</type>
        <dirname>1299</dirname>
        <basename>MAT 3.12-O QUE E RADIANO-230310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1301</id_resource>
        <id_file>17370</id_file>
        <type>8</type>
        <dirname>1301</dirname>
        <basename>MAT 4.22-ANEIS DE SATURNO-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1302</id_resource>
        <id_file>17374</id_file>
        <type>8</type>
        <dirname>1302</dirname>
        <basename>MAT 4.25-BIFES NA CHAPA-140910-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1303</id_resource>
        <id_file>17378</id_file>
        <type>8</type>
        <dirname>1303</dirname>
        <basename>MAT 4.11-CALCADAS-070110-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1304</id_resource>
        <id_file>17382</id_file>
        <type>8</type>
        <dirname>1304</dirname>
        <basename>MAT 4.6-CAMELOS-290310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1305</id_resource>
        <id_file>17386</id_file>
        <type>8</type>
        <dirname>1305</dirname>
        <basename>MAT 4.2-CAMINHOES DE ACUCAR-090410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1306</id_resource>
        <id_file>17390</id_file>
        <type>8</type>
        <dirname>1306</dirname>
        <basename>MAT 4.4-VERDADE-081209-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1308</id_resource>
        <id_file>17395</id_file>
        <type>8</type>
        <dirname>1308</dirname>
        <basename>MAT 4.24-CONTAGEM DE PASSOS-020910-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1309</id_resource>
        <id_file>17399</id_file>
        <type>8</type>
        <dirname>1309</dirname>
        <basename>MAT 4.21-DADOS NAO TRANSITIVOS-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1310</id_resource>
        <id_file>17403</id_file>
        <type>8</type>
        <dirname>1310</dirname>
        <basename>MAT 4.1-DIFERENCA PRIMOS-070410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1311</id_resource>
        <id_file>17407</id_file>
        <type>8</type>
        <dirname>1311</dirname>
        <basename>MAT 4.3-DIVISAO SEM INVEJA-101209-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1312</id_resource>
        <id_file>17415</id_file>
        <type>8</type>
        <dirname>1312</dirname>
        <basename>MAT 4.28-TRIANGULO EQUILATERO-020910-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1315</id_resource>
        <id_file>17421</id_file>
        <type>8</type>
        <dirname>1315</dirname>
        <basename>MAT 4.7-MEDIAS QUE IMPORTAM-111209-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1317</id_resource>
        <id_file>17426</id_file>
        <type>8</type>
        <dirname>1317</dirname>
        <basename>MAT 4.8-PERMUTACAO-070410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1319</id_resource>
        <id_file>17431</id_file>
        <type>8</type>
        <dirname>1319</dirname>
        <basename>MAT 4.12-PREJUIZO E HERANCA-220310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1320</id_resource>
        <id_file>17435</id_file>
        <type>8</type>
        <dirname>1320</dirname>
        <basename>MAT 4.16-SUBCONJUNTOS-161209-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1321</id_resource>
        <id_file>17439</id_file>
        <type>8</type>
        <dirname>1321</dirname>
        <basename>MAT 4.23-TAMANHO DA MESA-080610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1322</id_resource>
        <id_file>17443</id_file>
        <type>8</type>
        <dirname>1322</dirname>
        <basename>MAT 4.9-TAMANHO DA TERRA-200410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1323</id_resource>
        <id_file>17447</id_file>
        <type>8</type>
        <dirname>1323</dirname>
        <basename>MAT 4.15-TITIUS E BOLD-060410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1325</id_resource>
        <id_file>17452</id_file>
        <type>8</type>
        <dirname>1325</dirname>
        <basename>MAT 4.20-TUDO COMECA EM PIZZA-160610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1326</id_resource>
        <id_file>17456</id_file>
        <type>8</type>
        <dirname>1326</dirname>
        <basename>MAT 4.26-VOLUME CONES E CILINDROS-140910-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1328</id_resource>
        <id_file>17464</id_file>
        <type>8</type>
        <dirname>1328</dirname>
        <basename>MAT 6.32-3X+1-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1329</id_resource>
        <id_file>17468</id_file>
        <type>8</type>
        <dirname>1329</dirname>
        <basename>MAT 6.27-SEIS GRAUS DE SEPARACAO-230911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1330</id_resource>
        <id_file>17472</id_file>
        <type>8</type>
        <dirname>1330</dirname>
        <basename>MAT 6.17-CELULAS TRONCO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1362</id_resource>
        <id_file>17476</id_file>
        <type>8</type>
        <dirname>1362</dirname>
        <basename>MAT 6.31-CONJECTURA DE GOLDBACH-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1331</id_resource>
        <id_file>17480</id_file>
        <type>8</type>
        <dirname>1331</dirname>
        <basename>MAT 6.18-CONTEUDO UNIVERSO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1332</id_resource>
        <id_file>17484</id_file>
        <type>8</type>
        <dirname>1332</dirname>
        <basename>MAT 6.19-CURVA DO SINO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1333</id_resource>
        <id_file>17488</id_file>
        <type>8</type>
        <dirname>1333</dirname>
        <basename>MAT 6.25-O PESO DA DUVIDA-140911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1334</id_resource>
        <id_file>17492</id_file>
        <type>8</type>
        <dirname>1334</dirname>
        <basename>MAT 6.9-TEOREMA DA ESFERA COM CABELOS-250411-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1335</id_resource>
        <id_file>17496</id_file>
        <type>8</type>
        <dirname>1335</dirname>
        <basename>MAT 6.8-EXOPLANETAS-060611-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1336</id_resource>
        <id_file>17500</id_file>
        <type>8</type>
        <dirname>1336</dirname>
        <basename>MAT 6.1-FORMIGAS-160610-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1337</id_resource>
        <id_file>17504</id_file>
        <type>8</type>
        <dirname>1337</dirname>
        <basename>MAT 6.11-GENOMA-190711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1338</id_resource>
        <id_file>17508</id_file>
        <type>8</type>
        <dirname>1338</dirname>
        <basename>MAT 6.21-GRAVIDEZ NA ADOLESCENCIA-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1339</id_resource>
        <id_file>17512</id_file>
        <type>8</type>
        <dirname>1339</dirname>
        <basename>MAT 6.22-HORARIO DE VERAO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1340</id_resource>
        <id_file>17516</id_file>
        <type>8</type>
        <dirname>1340</dirname>
        <basename>MAT 6.6-IMC E IAC-100511-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1342</id_resource>
        <id_file>17521</id_file>
        <type>8</type>
        <dirname>1342</dirname>
        <basename>MAT 6.20-MAQUINAS DO TEMPO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1343</id_resource>
        <id_file>17525</id_file>
        <type>8</type>
        <dirname>1343</dirname>
        <basename>MAT 6.3-MUDANCAS CLIMATICAS-300810-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1344</id_resource>
        <id_file>17529</id_file>
        <type>8</type>
        <dirname>1344</dirname>
        <basename>MAT 6.14-NOS-200711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1345</id_resource>
        <id_file>17533</id_file>
        <type>8</type>
        <dirname>1345</dirname>
        <basename>MAT 6.4-NUMEROS PRIMOS-310810-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1346</id_resource>
        <id_file>17537</id_file>
        <type>8</type>
        <dirname>1346</dirname>
        <basename>MAT 6.26-OLIMPIADAS-140911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1347</id_resource>
        <id_file>17541</id_file>
        <type>8</type>
        <dirname>1347</dirname>
        <basename>MAT 6.33-NUMEROS PRIMOS-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1348</id_resource>
        <id_file>17545</id_file>
        <type>8</type>
        <dirname>1348</dirname>
        <basename>MAT 6.2A-REDES E AMAR-100511-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1350</id_resource>
        <id_file>17550</id_file>
        <type>8</type>
        <dirname>1350</dirname>
        <basename>MAT 6.28-DESVIO PADRAO-230911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1352</id_resource>
        <id_file>17555</id_file>
        <type>8</type>
        <dirname>1352</dirname>
        <basename>MAT 6.29-TAMANHO DOS PROTONS-230911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1353</id_resource>
        <id_file>17559</id_file>
        <type>8</type>
        <dirname>1353</dirname>
        <basename>MAT 6.10-TEMPESTADES SOLARES-190711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1354</id_resource>
        <id_file>17563</id_file>
        <type>8</type>
        <dirname>1354</dirname>
        <basename>MAT 6.12-TERREMOTOS-190711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1355</id_resource>
        <id_file>17567</id_file>
        <type>8</type>
        <dirname>1355</dirname>
        <basename>MAT 6.30-TIJOLOS DE EULLER-230911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1356</id_resource>
        <id_file>17571</id_file>
        <type>8</type>
        <dirname>1356</dirname>
        <basename>MAT 6.24-TITA-140911-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1357</id_resource>
        <id_file>17575</id_file>
        <type>8</type>
        <dirname>1357</dirname>
        <basename>MAT 6.15-TUBAROES-200711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1358</id_resource>
        <id_file>17579</id_file>
        <type>8</type>
        <dirname>1358</dirname>
        <basename>MAT 6.13-DNA-190711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1359</id_resource>
        <id_file>17583</id_file>
        <type>8</type>
        <dirname>1359</dirname>
        <basename>MAT 6.7-VENUS E TRIGONOMETRIA-200711-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1377</id_resource>
        <id_file>17701</id_file>
        <type>8</type>
        <dirname>1377</dirname>
        <basename>MAT 7.6-HORARIO DE VERAO-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1378</id_resource>
        <id_file>17709</id_file>
        <type>8</type>
        <dirname>1378</dirname>
        <basename>MAT 4.27-ESCALAS DE TEMPERATURA-020910-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1252</id_resource>
        <id_file>17725</id_file>
        <type>8</type>
        <dirname>1252</dirname>
        <basename>MAT 1.8A_SERIE CULTURA_ESTATISTICAS_BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1253</id_resource>
        <id_file>17729</id_file>
        <type>8</type>
        <dirname>1253</dirname>
        <basename>MAT 1.8B_SERIE CULTURA_PROBABILIDADE_BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1258</id_resource>
        <id_file>17852</id_file>
        <type>8</type>
        <dirname>1258</dirname>
        <basename>MAT 1.7A_SERIE CULTURA_PONTES_BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1261</id_resource>
        <id_file>17880</id_file>
        <type>8</type>
        <dirname>1261</dirname>
        <basename>MAT 1.5-SERIE CULTURA-VERDADE E MENTIRA-190410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1293</id_resource>
        <id_file>17884</id_file>
        <type>8</type>
        <dirname>1293</dirname>
        <basename>MAT 3.8-O QUE E PARABOLA-200310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1292</id_resource>
        <id_file>17888</id_file>
        <type>8</type>
        <dirname>1292</dirname>
        <basename>MAT 3.6-O QUE E LOGARITIMO-200310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1289</id_resource>
        <id_file>17892</id_file>
        <type>8</type>
        <dirname>1289</dirname>
        <basename>MAT 3.3-O QUE E EXPONENCIAL-220310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1287</id_resource>
        <id_file>17896</id_file>
        <type>8</type>
        <dirname>1287</dirname>
        <basename>MAT 3.2-O QUE E ELIPSE-200310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1256</id_resource>
        <id_file>17907</id_file>
        <type>8</type>
        <dirname>1256</dirname>
        <basename>MAT 1.2-PROGRESSAO ARITMETICA-MIX-170511-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1313</id_resource>
        <id_file>17911</id_file>
        <type>8</type>
        <dirname>1313</dirname>
        <basename>MAT 4.5-FRAUDE-191109-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1314</id_resource>
        <id_file>17915</id_file>
        <type>8</type>
        <dirname>1314</dirname>
        <basename>MAT 4.10-GASOLINA ADULTERADA-070410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1316</id_resource>
        <id_file>17919</id_file>
        <type>8</type>
        <dirname>1316</dirname>
        <basename>MAT 4.13-MORTOS VIVOS-161209-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1290</id_resource>
        <id_file>17924</id_file>
        <type>8</type>
        <dirname>1290</dirname>
        <basename>MAT 3.4-O QUE E HIPERBOLE-230310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1318</id_resource>
        <id_file>17928</id_file>
        <type>8</type>
        <dirname>1318</dirname>
        <basename>MAT 4.14-PESO EXTRA-110110-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1307</id_resource>
        <id_file>17932</id_file>
        <type>8</type>
        <dirname>1307</dirname>
        <basename>MAT 4.19-CONSUMO DE ENERGIA-140410-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1324</id_resource>
        <id_file>17940</id_file>
        <type>8</type>
        <dirname>1324</dirname>
        <basename>MAT 4.17-TRIANGULO IMPAR-081209-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1259</id_resource>
        <id_file>17944</id_file>
        <type>8</type>
        <dirname>1259</dirname>
        <basename>MAT 1.7B_SERIE CULTURA_GREGOS_BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1260</id_resource>
        <id_file>17948</id_file>
        <type>8</type>
        <dirname>1260</dirname>
        <basename>MAT 1.10-VAMPIROS-170511-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1341</id_resource>
        <id_file>17952</id_file>
        <type>8</type>
        <dirname>1341</dirname>
        <basename>MAT 6.5B-INFINITUDE DOS RACIONAIS-060611-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1351</id_resource>
        <id_file>17956</id_file>
        <type>8</type>
        <dirname>1351</dirname>
        <basename>MAT 6.2B-SOLIDOS CONVEXOS-100511-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1360</id_resource>
        <id_file>17960</id_file>
        <type>8</type>
        <dirname>1360</dirname>
        <basename>MAT 6.5A-VULCOES E PROBABILIDADES-060611-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1286</id_resource>
        <id_file>17968</id_file>
        <type>8</type>
        <dirname>1286</dirname>
        <basename>MAT 3.1-O QUE E BARICENTRO-190310-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1327</id_resource>
        <id_file>18007</id_file>
        <type>8</type>
        <dirname>1327</dirname>
        <basename>MAT 6.16-37 POR CENTO-230811-BL1.mp3</basename>
    </file>
    <file>
        <id_resource>1251</id_resource>
        <id_file>17210</id_file>
        <type>9</type>
        <dirname>1251</dirname>
        <basename>MAT 1.4-SEQUENCIA DE FIBONACCI-COM CREDITOS-220310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1254</id_resource>
        <id_file>17216</id_file>
        <type>9</type>
        <dirname>1254</dirname>
        <basename>MAT 1.9-SERIE CULTURA-MINHOCANDO-280810-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1255</id_resource>
        <id_file>17220</id_file>
        <type>9</type>
        <dirname>1255</dirname>
        <basename>MAT 1.6-JACKIL E MR HIDE-060611-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1257</id_resource>
        <id_file>17225</id_file>
        <type>9</type>
        <dirname>1257</dirname>
        <basename>MAT 1.3-PROGRESSAO GEOMETRICA-COM CREDITOS-230310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1262</id_resource>
        <id_file>17233</id_file>
        <type>9</type>
        <dirname>1262</dirname>
        <basename>MAT 7.1-SEIS GRAUS DE SEPARACAO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1263</id_resource>
        <id_file>17237</id_file>
        <type>9</type>
        <dirname>1263</dirname>
        <basename>MAT 7.3-H1N1-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1264</id_resource>
        <id_file>17241</id_file>
        <type>9</type>
        <dirname>1264</dirname>
        <basename>MAT 7.4-TERREMOTOS-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1265</id_resource>
        <id_file>17245</id_file>
        <type>9</type>
        <dirname>1265</dirname>
        <basename>MAT 7.2-CONTEUDO DO UNIVERSO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1266</id_resource>
        <id_file>17249</id_file>
        <type>9</type>
        <dirname>1266</dirname>
        <basename>MAT 7.5-VIOLENCIA-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1267</id_resource>
        <id_file>17253</id_file>
        <type>9</type>
        <dirname>1267</dirname>
        <basename>MAT 2.1-ANIMAIS NATUREZA-160310-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1268</id_resource>
        <id_file>17257</id_file>
        <type>9</type>
        <dirname>1268</dirname>
        <basename>MAT 2.10-COMBINACAO-COM CREDITOS-060510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1269</id_resource>
        <id_file>17261</id_file>
        <type>9</type>
        <dirname>1269</dirname>
        <basename>MAT 2.4-COMBUSTIVEL-120110-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1270</id_resource>
        <id_file>17265</id_file>
        <type>9</type>
        <dirname>1270</dirname>
        <basename>MAT 2.2-BATIMENTOS E CABELO-160310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1271</id_resource>
        <id_file>17269</id_file>
        <type>9</type>
        <dirname>1271</dirname>
        <basename>MAT 2.5-DISTANCIAS-030210-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1272</id_resource>
        <id_file>17273</id_file>
        <type>9</type>
        <dirname>1272</dirname>
        <basename>MAT 2.6-ESFERAS-120110-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1273</id_resource>
        <id_file>17277</id_file>
        <type>9</type>
        <dirname>1273</dirname>
        <basename>MAT 2.7-ESTIMATIVA LIXO-COM CREDITOS-050510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1274</id_resource>
        <id_file>17281</id_file>
        <type>9</type>
        <dirname>1274</dirname>
        <basename>MAT 2.8-MASSA E IDADE-COM CREDITOS-190310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1275</id_resource>
        <id_file>17285</id_file>
        <type>9</type>
        <dirname>1275</dirname>
        <basename>MAT 2.9-PIZZA E FEIJAO-COM CREDITOS-190310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1276</id_resource>
        <id_file>17289</id_file>
        <type>9</type>
        <dirname>1276</dirname>
        <basename>MAT 5.1-INFINITO 1-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1277</id_resource>
        <id_file>17293</id_file>
        <type>9</type>
        <dirname>1277</dirname>
        <basename>MAT 5.2-INFINITO 2-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1278</id_resource>
        <id_file>17297</id_file>
        <type>9</type>
        <dirname>1278</dirname>
        <basename>MAT 5.9-EQUACOES CUBICAS-100511-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1279</id_resource>
        <id_file>17301</id_file>
        <type>9</type>
        <dirname>1279</dirname>
        <basename>MAT 5.8-CRIACAO DE LOGARITMOS-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1280</id_resource>
        <id_file>17305</id_file>
        <type>9</type>
        <dirname>1280</dirname>
        <basename>MAT 5.3-NEGATIVOS-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1281</id_resource>
        <id_file>17309</id_file>
        <type>9</type>
        <dirname>1281</dirname>
        <basename>MAT 5.4-NEWTON E OS NUMEROS-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1282</id_resource>
        <id_file>17313</id_file>
        <type>9</type>
        <dirname>1282</dirname>
        <basename>MAT 5.6-COMPLEXOS-COM CREDITOS-160610-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1283</id_resource>
        <id_file>17317</id_file>
        <type>9</type>
        <dirname>1283</dirname>
        <basename>MAT 5.10-QUATRO E UM QUADRADO-250411-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1284</id_resource>
        <id_file>17321</id_file>
        <type>9</type>
        <dirname>1284</dirname>
        <basename>MAT 5.7-SENOS-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1285</id_resource>
        <id_file>17325</id_file>
        <type>9</type>
        <dirname>1285</dirname>
        <basename>MAT 5.5-INVENCAO DO ZERO-180610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1288</id_resource>
        <id_file>17331</id_file>
        <type>9</type>
        <dirname>1288</dirname>
        <basename>MAT 3.13-MARGEM DE ERRO-160610-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1291</id_resource>
        <id_file>17337</id_file>
        <type>9</type>
        <dirname>1291</dirname>
        <basename>MAT 3.5-O QUE E HIPOTENUSA-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1294</id_resource>
        <id_file>17343</id_file>
        <type>9</type>
        <dirname>1294</dirname>
        <basename>MAT 3.9-O QUE E PARALELOGRAMO-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1295</id_resource>
        <id_file>17347</id_file>
        <type>9</type>
        <dirname>1295</dirname>
        <basename>MAT 3.14-PERMUTACAO-COM CREDITOS-160610-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1296</id_resource>
        <id_file>17351</id_file>
        <type>9</type>
        <dirname>1296</dirname>
        <basename>MAT 3.10-O QUE E POLIEDRO-COM CREDITOS-080910-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1297</id_resource>
        <id_file>17355</id_file>
        <type>9</type>
        <dirname>1297</dirname>
        <basename>MAT 3.11-O QUE E POLINOMIO-COM CREDITOS-080910-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1298</id_resource>
        <id_file>17359</id_file>
        <type>9</type>
        <dirname>1298</dirname>
        <basename>MAT 3.7-O QUE E NUMERO PRIMO-COM CREDITOS-240510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1299</id_resource>
        <id_file>17363</id_file>
        <type>9</type>
        <dirname>1299</dirname>
        <basename>MAT 3.12-O QUE E RADIANO-COM CREDITOS-240510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1301</id_resource>
        <id_file>17371</id_file>
        <type>9</type>
        <dirname>1301</dirname>
        <basename>MAT 4.22-ANEIS DE SATURNO-080610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1302</id_resource>
        <id_file>17375</id_file>
        <type>9</type>
        <dirname>1302</dirname>
        <basename>MAT 4.25-BIFES NA CHAPA-140910-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1303</id_resource>
        <id_file>17379</id_file>
        <type>9</type>
        <dirname>1303</dirname>
        <basename>MAT 4.11-CALCADAS-070110-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1304</id_resource>
        <id_file>17383</id_file>
        <type>9</type>
        <dirname>1304</dirname>
        <basename>MAT 4.6-CAMELOS-COM CREDITOS-290310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1305</id_resource>
        <id_file>17387</id_file>
        <type>9</type>
        <dirname>1305</dirname>
        <basename>MAT 4.2-CAMINHOES DE ACUCAR-COM CREDITOS-090410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1306</id_resource>
        <id_file>17391</id_file>
        <type>9</type>
        <dirname>1306</dirname>
        <basename>MAT 4.4-VERDADE-081209-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1308</id_resource>
        <id_file>17396</id_file>
        <type>9</type>
        <dirname>1308</dirname>
        <basename>MAT 4.24-CONTAGEM DE PASSOS-020910-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1309</id_resource>
        <id_file>17400</id_file>
        <type>9</type>
        <dirname>1309</dirname>
        <basename>MAT 4.21-DADOS NAO TRANSITIVOS-080610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1310</id_resource>
        <id_file>17404</id_file>
        <type>9</type>
        <dirname>1310</dirname>
        <basename>MAT 4.1-DIFERENCA PRIMOS-COM CREDITOS-070410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1311</id_resource>
        <id_file>17408</id_file>
        <type>9</type>
        <dirname>1311</dirname>
        <basename>MAT 4.3-DIVISAO SEM INVEJA-101209-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1312</id_resource>
        <id_file>17416</id_file>
        <type>9</type>
        <dirname>1312</dirname>
        <basename>MAT 4.28-TRIANGULO EQUILATERO-020910-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1315</id_resource>
        <id_file>17422</id_file>
        <type>9</type>
        <dirname>1315</dirname>
        <basename>MAT 4.7-MEDIAS QUE IMPORTAM-111209-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1317</id_resource>
        <id_file>17427</id_file>
        <type>9</type>
        <dirname>1317</dirname>
        <basename>MAT 4.8-PERMUTACAO-COM CREDITOS-070410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1319</id_resource>
        <id_file>17432</id_file>
        <type>9</type>
        <dirname>1319</dirname>
        <basename>MAT 4.12-PREJUIZO E HERANCA-COM CREDITOS-220310-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1320</id_resource>
        <id_file>17436</id_file>
        <type>9</type>
        <dirname>1320</dirname>
        <basename>MAT 4.16-SUBCONJUNTOS-161209-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1321</id_resource>
        <id_file>17440</id_file>
        <type>9</type>
        <dirname>1321</dirname>
        <basename>MAT 4.23-TAMANHO DA MESA-080610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1322</id_resource>
        <id_file>17444</id_file>
        <type>9</type>
        <dirname>1322</dirname>
        <basename>MAT 4.9-TAMANHO DA TERRA-COM CREDITO-200410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1323</id_resource>
        <id_file>17448</id_file>
        <type>9</type>
        <dirname>1323</dirname>
        <basename>MAT 4.15-TITIUS E BOLD-COM CREDITOS-060410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1325</id_resource>
        <id_file>17453</id_file>
        <type>9</type>
        <dirname>1325</dirname>
        <basename>MAT 4.20-TUDO COMECA EM PIZZA-160610-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1326</id_resource>
        <id_file>17457</id_file>
        <type>9</type>
        <dirname>1326</dirname>
        <basename>MAT 4.26-VOLUME CONES E CILINDROS-140910-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1328</id_resource>
        <id_file>17465</id_file>
        <type>9</type>
        <dirname>1328</dirname>
        <basename>MAT 6.32-3X+1-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1329</id_resource>
        <id_file>17469</id_file>
        <type>9</type>
        <dirname>1329</dirname>
        <basename>MAT 6.27-SEIS GRAUS DE SEPARACAO-230911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1330</id_resource>
        <id_file>17473</id_file>
        <type>9</type>
        <dirname>1330</dirname>
        <basename>MAT 6.17-CELULAS TRONCO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1362</id_resource>
        <id_file>17477</id_file>
        <type>9</type>
        <dirname>1362</dirname>
        <basename>MAT 6.31-CONJECTURA DE GOLDBACH-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1331</id_resource>
        <id_file>17481</id_file>
        <type>9</type>
        <dirname>1331</dirname>
        <basename>MAT 6.18-CONTEUDO UNIVERSO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1332</id_resource>
        <id_file>17485</id_file>
        <type>9</type>
        <dirname>1332</dirname>
        <basename>MAT 6.19-CURVA DO SINO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1333</id_resource>
        <id_file>17489</id_file>
        <type>9</type>
        <dirname>1333</dirname>
        <basename>MAT 6.25-O PESO DA DUVIDA-140911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1334</id_resource>
        <id_file>17493</id_file>
        <type>9</type>
        <dirname>1334</dirname>
        <basename>MAT 6.9-TEOREMA DA ESFERA COM CABELOS-250411-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1335</id_resource>
        <id_file>17497</id_file>
        <type>9</type>
        <dirname>1335</dirname>
        <basename>MAT 6.8-EXOPLANETAS-060611-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1336</id_resource>
        <id_file>17501</id_file>
        <type>9</type>
        <dirname>1336</dirname>
        <basename>MAT 6.1-FORMIGAS-COM CREDITOS-160610-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1337</id_resource>
        <id_file>17505</id_file>
        <type>9</type>
        <dirname>1337</dirname>
        <basename>MAT 6.11-GENOMA-190711-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1338</id_resource>
        <id_file>17509</id_file>
        <type>9</type>
        <dirname>1338</dirname>
        <basename>MAT 6.21-GRAVIDEZ NA ADOLESCENCIA-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1339</id_resource>
        <id_file>17513</id_file>
        <type>9</type>
        <dirname>1339</dirname>
        <basename>MAT 6.22-HORARIO DE VERAO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1340</id_resource>
        <id_file>17517</id_file>
        <type>9</type>
        <dirname>1340</dirname>
        <basename>MAT 6.6-IMC E IAC-100511-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1342</id_resource>
        <id_file>17522</id_file>
        <type>9</type>
        <dirname>1342</dirname>
        <basename>MAT 6.20-MAQUINAS DO TEMPO-230811-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1343</id_resource>
        <id_file>17526</id_file>
        <type>9</type>
        <dirname>1343</dirname>
        <basename>MAT 6.3-MUDANCAS CLIMATICAS-300810-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1344</id_resource>
        <id_file>17530</id_file>
        <type>9</type>
        <dirname>1344</dirname>
        <basename>MAT 6.14-NOS-200711-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1345</id_resource>
        <id_file>17534</id_file>
        <type>9</type>
        <dirname>1345</dirname>
        <basename>MAT 6.4-NUMEROS PRIMOS-310810-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1346</id_resource>
        <id_file>17538</id_file>
        <type>9</type>
        <dirname>1346</dirname>
        <basename>MAT 6.26-OLIMPIADAS-140911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1347</id_resource>
        <id_file>17542</id_file>
        <type>9</type>
        <dirname>1347</dirname>
        <basename>MAT 6.33-NUMEROS PRIMOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1348</id_resource>
        <id_file>17546</id_file>
        <type>9</type>
        <dirname>1348</dirname>
        <basename>MAT 6.2A-REDES E AMAR-100511-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1350</id_resource>
        <id_file>17551</id_file>
        <type>9</type>
        <dirname>1350</dirname>
        <basename>MAT 6.28-DESVIO PADRAO-230911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1352</id_resource>
        <id_file>17556</id_file>
        <type>9</type>
        <dirname>1352</dirname>
        <basename>MAT 6.29-TAMANHO DOS PROTONS-230911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1353</id_resource>
        <id_file>17560</id_file>
        <type>9</type>
        <dirname>1353</dirname>
        <basename>MAT 6.10-TEMPESTADES SOLARES-190711-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1354</id_resource>
        <id_file>17564</id_file>
        <type>9</type>
        <dirname>1354</dirname>
        <basename>MAT 6.12-TERREMOTOS-190711-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1355</id_resource>
        <id_file>17568</id_file>
        <type>9</type>
        <dirname>1355</dirname>
        <basename>MAT 6.30-TIJOLOS DE EULLER-230911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1356</id_resource>
        <id_file>17572</id_file>
        <type>9</type>
        <dirname>1356</dirname>
        <basename>MAT 6.24-TITA-140911-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1357</id_resource>
        <id_file>17576</id_file>
        <type>9</type>
        <dirname>1357</dirname>
        <basename>MAT 6.15-TUBAROES-200711-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1358</id_resource>
        <id_file>17580</id_file>
        <type>9</type>
        <dirname>1358</dirname>
        <basename>MAT 6.13-DNA-190711-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1359</id_resource>
        <id_file>17584</id_file>
        <type>9</type>
        <dirname>1359</dirname>
        <basename>MAT 6.7-VENUS E TRIGONOMETRIA-250411-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1377</id_resource>
        <id_file>17702</id_file>
        <type>9</type>
        <dirname>1377</dirname>
        <basename>MAT 7.6-HORARIO DE VERAO-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1378</id_resource>
        <id_file>17710</id_file>
        <type>9</type>
        <dirname>1378</dirname>
        <basename>MAT 4.27-ESCALAS DE TEMPERATURA-020910-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1261</id_resource>
        <id_file>17881</id_file>
        <type>9</type>
        <dirname>1261</dirname>
        <basename>MAT 1.5-SERIE CULTURA-VERDADE E MENTIRA-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1293</id_resource>
        <id_file>17885</id_file>
        <type>9</type>
        <dirname>1293</dirname>
        <basename>MAT 3.8-O QUE E PARABOLA-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1292</id_resource>
        <id_file>17889</id_file>
        <type>9</type>
        <dirname>1292</dirname>
        <basename>MAT 3.6-O QUE E LOGARITIMO-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1289</id_resource>
        <id_file>17893</id_file>
        <type>9</type>
        <dirname>1289</dirname>
        <basename>MAT 3.3-O QUE E EXPONENCIAL-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1287</id_resource>
        <id_file>17897</id_file>
        <type>9</type>
        <dirname>1287</dirname>
        <basename>MAT 3.2-O QUE E ELIPSE-COM CREDITOS-080910-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1256</id_resource>
        <id_file>17908</id_file>
        <type>9</type>
        <dirname>1256</dirname>
        <basename>MAT 1.2-PROGRESSAO ARITMETICA-MIX-170511-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1313</id_resource>
        <id_file>17912</id_file>
        <type>9</type>
        <dirname>1313</dirname>
        <basename>MAT 4.5-FRAUDE-COM CREDITOS-191109-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1314</id_resource>
        <id_file>17916</id_file>
        <type>9</type>
        <dirname>1314</dirname>
        <basename>MAT 4.10-GASOLINA ADULTERADA-COM CREDITOS-070410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1316</id_resource>
        <id_file>17920</id_file>
        <type>9</type>
        <dirname>1316</dirname>
        <basename>MAT 4.13-MORTOS VIVOS-161209-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1290</id_resource>
        <id_file>17925</id_file>
        <type>9</type>
        <dirname>1290</dirname>
        <basename>MAT 3.4-O QUE E HIPERBOLE-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1318</id_resource>
        <id_file>17929</id_file>
        <type>9</type>
        <dirname>1318</dirname>
        <basename>MAT 4.14-PESO EXTRA-110110-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1307</id_resource>
        <id_file>17933</id_file>
        <type>9</type>
        <dirname>1307</dirname>
        <basename>MAT 4.19-CONSUMO DE ENERGIA-COM CREDITOS-140410-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1324</id_resource>
        <id_file>17941</id_file>
        <type>9</type>
        <dirname>1324</dirname>
        <basename>MAT 4.17-TRIANGULO IMPAR-081209-COM CREDITOS-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1286</id_resource>
        <id_file>17969</id_file>
        <type>9</type>
        <dirname>1286</dirname>
        <basename>MAT 3.1-O QUE E BARICENTRO-COM CREDITOS-210510-BL2.mp3</basename>
    </file>
    <file>
        <id_resource>1327</id_resource>
        <id_file>18008</id_file>
        <type>9</type>
        <dirname>1327</dirname>
        <basename>MAT 6.16-37 POR CENTO-230811-BL2.mp3</basename>
    </file>
</root>`

export default files
